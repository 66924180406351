import React, { useState, useRef, useEffect } from 'react';
import './BoardWriteText.scss';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import fnc from '../mymod/commFunction';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from 'react-redux';

const BoardWriteText = ({ fncClose }) => {
  const navigate = useNavigate();
  const [title, setTitle] = useState('');
  const [jindo, setJindo] = useState('');
  const [homework, setHomework] = useState('');
  const [content, setContent] = useState('');
  const [startDate, setStartDate] = useState(new Date());

  const classCode = useSelector((state) => state.reduxStore.classCode);



  const titleInputRef = useRef(null);

  useEffect(() => {
    setStartDate(new Date()); // Ensure startDate is initialized correctly

    if (titleInputRef.current) {
      titleInputRef.current.focus();
    }
  }, []);

  function onPrev() {
    fncClose();
  }



  function fncInsertBoard() {
    const acaCode = fnc.getCookie('acaCode');
    const stCode = fnc.getCookie('stCode');
    const writer = fnc.getCookie('userName');
    const code = fnc.fncSirialNum('l');
    const kind='lesson';
    const sDate =fnc.formatDate(startDate);

    if (!title) {
      alert('제목을 쓰세요!');
      return null;
    }

    if (!content) {
      alert('내용을 쓰세요!');
      return null;
    }

    if (!jindo) {
      alert('진도를 쓰세요!');
      return null;
    }

    if (!homework) {
      alert('과제를 쓰세요!');
      return null;
    }

    const rtn = window.confirm('글을 등록하시겠습니까?');
    if (!rtn) return;

    fnc.sendAxios("/insert/lmsTec/course/boardWite", 
        { acaCode, stCode, classCode, title, content, jindo, homework, writer, code, kind, sDate}, (res) => {
        if (res) {
          onPrev();
        } else {
            console.log(res, "속성이 obj 내에 없습니다.");
        }
    });
}

  //=========================================

  return (
    <div className="BoardWriteText">
      <div className="box_jodit">
        <div className="formGroup">
          <input
            className="titleInput"
            spellCheck='false'
            autoComplete="1"  // 임의의 값 설정 포커스시 팝업 안뜨게
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            placeholder="제목을 입력하세요"
            ref={titleInputRef}
          />
          <DatePicker
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            dateFormat="yyyy-MM-dd"
            className="datePicker"
          />
        </div>

        <input
            className="jindoInput"
            spellCheck='false'
            autoComplete="1"  // 임의의 값 설정 포커스시 팝업 안뜨게
            type="text"
            value={jindo}
            onChange={(e) => setJindo(e.target.value)}
            placeholder="진도를 입력하세요"

          />

          <input
            className="homeworkInput"
            spellCheck='false'
            autoComplete="1"  // 임의의 값 설정 포커스시 팝업 안뜨게
            type="text"
            value={homework}
            onChange={(e) => setHomework(e.target.value)}
            placeholder="과제를 입력하세요"

          />


        <div className="box-textArea">
          <textarea
            name="postContent"
            id="postContent"
            spellCheck='false'
            rows="10"
            cols="50"
            placeholder="여기에 게시글을 작성하세요..."
            value={content}
            onChange={(event) => setContent(event.target.value)}
          ></textarea>
        </div>

        <div className="box_btnMenu">
          <Button
            className="btnPrev"
            variant="contained"
            onClick={onPrev}
          >
            이전으로
          </Button>
          <Button
            className="btnSave"
            variant="contained"
            onClick={fncInsertBoard}
          >
            저장하기
          </Button>
        </div>
      </div>
    </div>
  );
};

export default BoardWriteText;

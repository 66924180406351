import './Exam_chartDetail.scss';
import React, { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import fnc from '../mymod/commFunction';
import Button from '@mui/material/Button';

import { useDispatch, useSelector } from 'react-redux';
import { setList_pdf, setRowIdx_pdf, setKind, setList_pdf_marking, setList_pdf_complete, 
          setSelectedPage_pdf, setHeaderInfo, setSelectedBookDetailCode, setIsBookStudy } from '../reduxToolkit/redux';


const Exam_chartDetail = ({stCode, testCode}) => {//selectedItem이건 bookCode: 1345임 value: 1345가 아니고
    const location = useLocation();
    const imgUrl = process.env.REACT_APP_imgUrl;
    const dispatch = useDispatch();

    const selectedUnit_pdf = useSelector(state => state.reduxStore.selectedUnit_pdf);
    const isRefresh_bookMain = useSelector(state => state.reduxStore.isRefresh_bookMain);
    const userKind = useSelector(state => state.reduxStore.userInfo.kind);
    const selectedBookDetailCode = useSelector(state => state.reduxStore.selectedBookDetailCode);
    const isBookStudy = useSelector(state => state.reduxStore.isBookStudy);
    
    const [list, setList] = useState([]);
    const [isModal, setIsModal] = useState(false);
    const [isModal_memo, setIsModal_memo] = useState(false);
    const [isModal_marking, setIsModal_marking] = useState(false);
    const [isModal_complete, setIsModal_complete] = useState(false);
    // const [selectedListItem, setSelectedListItem] = useState({}); 
    const [selectedItems, setSelectedItems] = useState([]);//체크박스
    const [selectAll, setSelectAll] = useState(false);//체크박스

    // console.log('Current path:', location.pathname);


    useEffect(() => {
      fncSelectList();
      // console.log(selectedListItem, '---selectedListItem22');
  
    }, [testCode, stCode]);

    
  
  function fncSelectList() {
    const acaCode = fnc.getCookie('acaCode');
    // const stCode = fnc.getCookie('stCode');
    // const testCode = selectedListItem.testCode;

    fnc.sendAxios('/select/exam/report/detail', {stCode, testCode }, (res) => {
      if (res && Array.isArray(res)) { //단원선택 클릭 시 결과 (오른쪽 페이지 list)
        console.log(res, ' bookCode list');
        // setList(res);
        const newList = getRecords_list(res);
        setList(newList);

      } else {
        console.log(res,"kuesbChartDetailRoutes/select/book/pdfList");
      }
    });
  }
  
  function getRecords_list(res) {
    let records = [];
    for (let i = 0; i < res.length; i++) {      
        const no= res[i]["no"];
        const unit1 = res[i]["unit1"];
        const unit2 = res[i]["unit2"];
        const unit3 = res[i]["unit3"];
        const unit4 = res[i]["unit4"];
        const difficulty = res[i]["difficulty"];
        const OX = res[i]["OX"];
        const point = res[i]["point"];
        const Ocnt = res[i]["Ocnt"];
        const Xcnt = res[i]["Xcnt"];
        const correctRate = Ocnt + Xcnt > 0 ? (Ocnt / (Ocnt + Xcnt)) * 100 : 0;

        records.push({ no, unit1, unit2, unit3, unit4, difficulty, OX, point, Ocnt, Xcnt, correctRate: correctRate.toFixed(1) });
    }
    return records;
  }



    //=========================================
    return (
      //자식 컨포넌트
      <div className="Exam_chartDetail">
        <div className="wrap-book-main">
          <section className="section-book-main">
            <table className="table-hong table-book-main">
              <thead>
                <tr>
                  <th>번호</th>
                  <th>단원</th>
                  <th>난도</th>
                  <th>배점</th>
                  <th>OX</th>
                  <th>맞음(명)</th>
                  <th>틀림(명)</th>
                  <th>정답율</th>
              
                </tr>
              </thead>

              <tbody>

               { list.map((item) =>  {

                  return(
                      <tr key={item.no}>
                        <td>{item.no}</td>
                        <td>{item.unit2} <br/> {item.unit3}</td>
                        <td>{item.difficulty}</td>
                        <td>{item.point}</td>
                        <td>{item.OX}</td>
                      
                        <td>{item.Ocnt}</td>
                        <td>{item.Xcnt}</td>
                        <td>{item.correctRate}</td>
                      </tr>
                  );

                })}
                
              </tbody>

            </table>
          </section>




        </div>
     
      </div>
    );
}

export default Exam_chartDetail;
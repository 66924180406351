import './DigitalFastSearch.scss';
import React, { useEffect, useState } from 'react';
import HeaderTitle from '../comm/HeaderTitle';
import fnc from '../mymod/commFunction';
import { Button } from '@mui/material';
import { useDispatch } from 'react-redux';
import { setHeaderInfo, setIsBookStudy, setSelectedUnit_pdf } from '../reduxToolkit/redux';

const DigitalFastSearch = ({setIsFastSearch, setSelectedUnitItem}) => {
  const [teacherId, setTeacherId] = useState('');
  const [unit1Options, setUnit1Options] = useState([]);
  const [unit2Options, setUnit2Options] = useState([]);
  const [unit3Options, setUnit3Options] = useState([]);
  const [unit4Options, setUnit4Options] = useState([]);
  const [unit5Options, setUnit5Options] = useState([]);
  const [unit6Options, setUnit6Options] = useState([]);
  const [unit7Options, setUnit7Options] = useState([]);
  const [selectedNodeKey, setSelectedNodeKey] = useState(null);
  const [selectedNodeName, setSelectedNodeName] = useState(null);
  const [selectedBookCode, setSelectedBookCode] = useState(null);
  const [testList, setTestList] = useState([]);
  const [activeButtons, setActiveButtons] = useState({});
  const dispatch = useDispatch();

  useEffect(() => {
    // fetchOptions('ROOT', setUnit1Options);
    fncComboRestore();
  }, []);

  
  useEffect(() => {//리스트 복원

    const unitName='unit5_digital';
    const key = fnc.getCookie_pure(unitName);
    const activeItem = unit5Options.filter(pre => pre.childKey === key);
    if (activeItem.length > 0) {
      const nodeKeyValue = activeItem[0].childKey;
      const nodeName = activeItem[0].childName;
      const bookCode = activeItem[0].bookCode;

      fncSelectTestnmList(nodeKeyValue, nodeName, bookCode)
    }

  }, [unit5Options])


  function fncComboRestore() {
    let key, unitName;

    fetchOptions('ROOT', setUnit1Options);

    unitName='unit1';
    key = fnc.getCookie_pure(`${unitName}_digital`);
    if (key) {
      fetchOptions_restore(key, setUnit2Options, unitName);
    }

    unitName='unit2';
    key = fnc.getCookie_pure(`${unitName}_digital`);

    if (key) {
      fetchOptions_restore(key, setUnit3Options, unitName);
    }

    unitName='unit3';
    key = fnc.getCookie_pure(`${unitName}_digital`);
  
    if (key) {
      fetchOptions_restore(key, setUnit4Options, unitName);
    }

    
    unitName='unit4';
    key = fnc.getCookie_pure(`${unitName}_digital`);
    if (key) {
      fetchOptions_restore(key, setUnit5Options, unitName);
    }
    
    unitName='unit5';
    key = fnc.getCookie_pure(`${unitName}_digital`);
    if (key) {
      fetchOptions_restore(key, setUnit6Options, unitName);
    }

    unitName='unit6';
    key = fnc.getCookie_pure(`${unitName}_digital`);
    if (key) {
      fetchOptions_restore(key, setUnit7Options, unitName);
    }

  }

  const fetchOptions = (parentKey, setOptions) => {//setOptions은 setUnit1Options의 주소 연결임
    fncSelect_exam_list_fastSearch(parentKey, setOptions);
  };

  const fncSelect_exam_list_fastSearch = (parentKey, setOptions) => {
    const userId = teacherId;

    fnc.sendAxios('/select/book/combo_digital', { parentKey }, (res) => {//기존걸 그대로 사용 함 (선생님ID는 무시함)
      if (res && Array.isArray(res)) {
        setOptions(res);
      } else {
        console.log(res, 'fncSelect_exam_list_complete');
      }
    });
  };

  
  const fetchOptions_restore = (parentKey, setOptions, unitName) => {//setOptions은 setUnit1Options의 주소 연결임
    // console.log(setOptions, ' setOptions info');
    fncSelect_exam_list_fastSearch_restore(parentKey, setOptions, unitName);

  };

  const fncSelect_exam_list_fastSearch_restore = (parentKey, setOptions, unitName) => {
    const userId = teacherId;

    fnc.sendAxios('/select/book/combo_digital', { parentKey }, (res) => {//콤보 get set바꾸고 여기 한 군데만 바꾸면 돼
      if (res && Array.isArray(res)) {
        setOptions(res);
        setActiveButtons(prevState => ({...prevState, [unitName]: parentKey}));
        // fncResetBtn(unitName);//버튼 초기화
      } else {
        console.log(res, 'fncSelect_exam_list_complete');
      }
    });
  };


 
  const handleButtonClick = (key, name, bookCode, unitName, setOptions) => {
    setActiveButtons(prevState => ({
      ...prevState,
      [unitName]: key
    }));

    fnc.setCookie_pure(`${unitName}_digital`, key);
    fetchOptions(key, setOptions);

    if(unitName =='unit5' || unitName =='unit6') {
      fncSelectTestnmList(key, name, bookCode);
    }

    fncResetBtn(unitName);//버튼 초기화

  };


  function fncResetBtn(unitName) {
    // 하위 버튼 초기화
    switch (unitName) {
      case 'unit1':
        setUnit2Options([]);
        setUnit3Options([]);
        setUnit4Options([]);
        setUnit5Options([]);
        setUnit6Options([]);
        setUnit7Options([]);
        setActiveButtons(prevState => ({
          ...prevState,
          unit2: null,
          unit3: null,
          unit4: null,
          unit5: null,
          unit6: null,
          unit7: null
        }));

        fnc.setCookie_pure('unit2_digital', null);
        fnc.setCookie_pure('unit3_digital', null);
        fnc.setCookie_pure('unit4_digital', null);
        fnc.setCookie_pure('unit5_digital', null);
        fnc.setCookie_pure('unit6_digital', null);
        fnc.setCookie_pure('unit7_digital', null);

        break;
      case 'unit2':
        setUnit3Options([]);
        setUnit4Options([]);
        setUnit5Options([]);
        setUnit6Options([]);
        setUnit7Options([]);
        setActiveButtons(prevState => ({
          ...prevState,
          unit3: null,
          unit4: null,
          unit5: null,
          unit6: null,
          unit7: null
        }));

        fnc.setCookie_pure('unit3_digital', null);
        fnc.setCookie_pure('unit4_digital', null);
        fnc.setCookie_pure('unit5_digital', null);
        fnc.setCookie_pure('unit6_digital', null);
        fnc.setCookie_pure('unit7_digital', null);

        break;
      case 'unit3':
        setUnit4Options([]);
        setUnit5Options([]);
        setUnit6Options([]);
        setUnit7Options([]);
        setActiveButtons(prevState => ({
          ...prevState,
          unit4: null,
          unit5: null,
          unit6: null,
          unit7: null
        }));

        fnc.setCookie_pure('unit4_digital', null);
        fnc.setCookie_pure('unit5_digital', null);
        fnc.setCookie_pure('unit6_digital', null);
        fnc.setCookie_pure('unit7_digital', null);
        break;
      case 'unit4':
        setUnit5Options([]);
        setUnit6Options([]);
        setUnit7Options([]);
        setActiveButtons(prevState => ({
          ...prevState,
          unit5: null,
          unit6: null,
          unit7: null
        }));

        fnc.setCookie_pure('unit5_digital', null);
        fnc.setCookie_pure('unit6_digital', null);
        fnc.setCookie_pure('unit7_digital', null);

        break;
      case 'unit5':
        setUnit6Options([]);
        setUnit7Options([]);
        setActiveButtons(prevState => ({
          ...prevState,
          unit6: null,
          unit7: null
        }));

        fnc.setCookie_pure('unit6_digital', null);
        fnc.setCookie_pure('unit7_digital', null);
        break;

      case 'unit6':
        setUnit7Options([]);
        setActiveButtons(prevState => ({
          ...prevState,
          unit7: null
        }));

        fnc.setCookie_pure('unit7_digital', null);

        break;
      default:
        break;
    }

  }

  function fncSelectTestnmList(nodeKeyValue, nodeName, bookCode) {
    const userId = teacherId;
    const acaCode = fnc.getCookie('acaCode');
    const stCode = fnc.getCookie('stCode');
    const source = bookCode;

    setSelectedNodeKey(nodeKeyValue);//여기선 bookcode만 사용 함
    setSelectedNodeName(nodeName);
    setSelectedBookCode(bookCode);

    //여기는 하단 리스트 안 뿌린다.


    // fnc.sendAxios('/select/book/pdfList', {acaCode, stCode, source }, (res) => {//기존에 있던 쿼리
    //   if (res && Array.isArray(res)) {
    //     setTestList(res);
    //     console.log(res, 'res===============');
    //   } else {
    //     console.log(res, 'select/exam/fastSearch/testName');
    //   }
    // });
  };

  function onConfirm() {//확인 버튼
   if(!selectedNodeKey) {
    alert('대상이 없습니다.');
    return;
   }
    setSelectedUnitItem({value: selectedNodeKey, label: selectedNodeName, bookCode: selectedBookCode});
    dispatch(setHeaderInfo({ title: selectedNodeName, direction: -1 }));
    dispatch(setSelectedUnit_pdf(selectedNodeName));
    setIsFastSearch(false);
    dispatch(setIsBookStudy(false));

  }
  //=======================================================

  return (
    <div className="DigitalFastSearch">
      {/* <HeaderTitle onBackClick={() => {setIsFastSearch(false)}} title=" " direction={1} 
        style={{color: 'var(--h-red)', border: 'none', backgroundColor: 'white', 
          borderBottom:'0px solid #ccc'}}
      /> */}

      <Button className='btnClose-me' onClick={() => setIsFastSearch(false)} >
                <i class="fa-solid fa-xmark"></i>
      </Button> 

      <div className="main">
        <div className="main-inner">
           {/* ID는 일단 죽여 놓는다. */}
           {false && <div className="boxRect">
            <div className="lblTecId">선생님ID</div>
            <div className="unit1">
              <input
                type="text"
                className="txtTeacherID"
                value={teacherId}
                onChange={(e) => setTeacherId(e.target.value)}
              />
            </div>
          </div> }

          <UnitBox label="개정" options={unit1Options} activeKey={activeButtons.unit1} unitName="unit1" onOptionClick={handleButtonClick} setOptions={setUnit2Options} />
          <UnitBox label="구분" options={unit2Options} activeKey={activeButtons.unit2} unitName="unit2" onOptionClick={handleButtonClick} setOptions={setUnit3Options} />
          <UnitBox label="과목" options={unit3Options} activeKey={activeButtons.unit3} unitName="unit3" onOptionClick={handleButtonClick} setOptions={setUnit4Options} />
          <UnitBox label="학년" options={unit4Options} activeKey={activeButtons.unit4} unitName="unit4" onOptionClick={handleButtonClick} setOptions={setUnit5Options} />
          <UnitBox label="교재" options={unit5Options} activeKey={activeButtons.unit5} unitName="unit5" onOptionClick={handleButtonClick} setOptions={setUnit6Options} />
         {false && <UnitBox label="단원" options={unit6Options} activeKey={activeButtons.unit6} unitName="unit6" onOptionClick={handleButtonClick} setOptions={setUnit7Options} /> }

          {/*여기선 사용 안 함 */}
         {false && <section className="section-list">
            <div className="box-tb_pdf_list" >
              <table className="table-hong">
                <thead>
                  <tr>
                    <th>no</th>
                    <th>단원명</th>
                    <th>페이지</th>
                    <th>문항수</th>
                  </tr>
                </thead>
                <tbody>
                  {testList.map((test, index) => (
                    <tr key={index} className="row_testnm_list">
                      <td><span>{index + 1}</span></td>
                      <td><span>{selectedNodeName}</span></td>
                      <td style={{ textAlign: 'left', paddingLeft: '0.5rem' }}><span>{test.page}</span></td>
                      <td><span>{test.munCnt}</span></td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </section> }

          <section className="section-menu-btn">         
              <Button className="btnCancel"  onClick={() => {setIsFastSearch(false)}}>취소</Button>
              <Button className="btnConfirm"  onClick={onConfirm}>확인</Button>
          </section>
        </div>
      </div>
    </div>
  );
};

const UnitBox = ({ label, options, activeKey, unitName, onOptionClick, setOptions }) => {

  return (
    <div className="boxRect">
      <div className="lblUnit">{label}</div>
      <div className="unit">
        {options.map((option, index) => (
          <button
            key={index}
            className={`btnUnit ${activeKey === option.childKey ? 'active' : ''}`}
            onClick={() => onOptionClick(option.childKey, option.childName, option.bookCode, unitName, setOptions)}
          >
            <div className="childName">
              {option.childName} {option.testCnt > 0 ? <span className="testCnt">({option.testCnt})</span> : ""}
            </div>
          </button>
        ))}
      </div>
    </div>
  );
};

export default DigitalFastSearch;

import './Exam2Main.scss';
import * as S from '../scss/theme';
import React, { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import fnc from '../mymod/commFunction';
import Button from '@mui/material/Button';
import imgHwp from '../images/svg/downloads.svg';
import imgReport from '../images/svg/report.svg';
import HeaderTitle from "../comm/HeaderTitle";

import { useDispatch, useSelector } from 'react-redux';
import { setRowIdx_pdf, setKind, setList_pdf_marking, setList_pdf_complete, 
         setSelectedPage_pdf, setHeaderInfo, setHeader_dev, setSelectedTestNm, setSelectedBookDetailCode, 
         setSelectedStCode} from '../reduxToolkit/redux';

import BookPdf_marking from './Exam3Marking';
import Exam5Complete from './Exam5Complete';
import MemoCanvas from '../drawSvg/MemoCanvas';
import Exam_ChartSt from './Exam_ChartSt';
import Exam_chartDetail from './Exam_chartDetail';
import Exam_chartDetailTec from './Exam_chartDetailTec';
import Exam_gradeCut from './Exam_gradeCut';
import Checkbox from '@mui/material/Checkbox';
// import RippleButton from '../comm/RippleButton';

const Exam2Main = ({ selectedItem }) => {
    const location = useLocation();
    const imgUrl = process.env.REACT_APP_imgUrl;
    const dispatch = useDispatch();

    const selectedUnit_pdf = useSelector(state => state.reduxStore.selectedUnit_pdf);
    const isRefresh_examMain = useSelector(state => state.reduxStore.isRefresh_examMain);
    let userKind = useSelector(state => state.reduxStore.userInfo.kind);
    // let selectedUserKind = useSelector(state => state.reduxStore.selectedUserKind);
    const selectedBookDetailCode = useSelector(state => state.reduxStore.selectedBookDetailCode);
    const isBookStudy = useSelector(state => state.reduxStore.isBookStudy);



    const selectedStCode = useSelector(state => state.reduxStore.selectedStCode);
    
    const [list, setList] = useState([]);
    const [isModal, setIsModal] = useState(false);
    const [isModal_memo, setIsModal_memo] = useState(false);
    const [isModal_marking, setIsModal_marking] = useState(false);
    const [isModal_complete, setIsModal_complete] = useState(false);
    const [isModal_chart, setIsModal_chart] = useState(false);
    const [isModal_chartTec, setIsModal_chartTec] = useState(false);
    const [isModal_gradeCut, setIsModal_gradeCut] = useState(false);
    
    const [selectedListItem, setSelectedListItem] = useState({});

    const [selectedItems, setSelectedItems] = useState([]);//체크박스
    const [selectAll, setSelectAll] = useState(false);//체크박스
    const [selectedItemsName, setSelectedItemsName] = useState([]);//체크박스
    const [title, setTitle] = useState("");

    const stCode = fnc.getCookie('stCode');

    useEffect(() => {
      dispatch(setHeader_dev('Exam2Main'));

    }, []);


    useEffect(() => {
       
        if(selectedBookDetailCode.length > 0) {
            const detailCode = selectedBookDetailCode.map(code => `'${code}'`).join(',');//in절에서 문자가 있어 따옴표로 감쌓야
            fncSelect_exam_list_detailCode(selectedItem.value, detailCode);
          }else {
            fncSelect_exam_list(selectedItem.value);
          }

    }, [selectedItem, isRefresh_examMain]);

    const fncSelect_exam_list = (bookCode) => {
        const acaCode = fnc.getCookie('acaCode');
        const stCode = fnc.getCookie('stCode');
        const kind = '';

        fnc.sendAxios('/select/exam/examList', { acaCode, stCode, nodeKeyValue: bookCode, kind }, (res) => {
            if (res && Array.isArray(res)) {
                // console.log(res, 'gradeCut');
                setList(res);
            } else {
                console.log(res, "select/book/pdfList");
            }
        });
    };

    const fncSelect_exam_list_detailCode = (bookCode, detailCode) => {
        const acaCode = fnc.getCookie('acaCode');
        const stCode = fnc.getCookie('stCode');
        // const stCode = selectedStCode;
        const kind = '';


        fnc.sendAxios('/select/exam/examList_detailCode', { acaCode, stCode, nodeKeyValue: bookCode, kind, detailCode }, (res) => {
            if (res && Array.isArray(res)) {
                setList(res);//Lms검증에서 넘어온 경우?
                dispatch(setSelectedBookDetailCode([]));//초기화를 해줘야 출판물 문제 클릭 시 전체가 나옴
            } else {
                console.log(res, "select/book/pdfList");
            }
        });
    };

    const onClickTr = (item) => {
        setSelectedListItem(item);
        const label = item.testNm;
        dispatch(setSelectedTestNm(label));
        dispatch(setHeaderInfo({ title: label, direction: -1 }));
    };


    function onClickMarkingOrComplete(item) {

      if (item.chejumYN === 'Y') {
        fncSelect_exam_list_complete(item.testCode, item.testCodeMun);
      } else {
        fncSelect_exam_list_marking(item.testCode, item.testCodeMun);
      }
     
    };


    const fncSelect_exam_list_marking = (testCode, testCodeMun) => {
        fnc.sendAxios('/select/exam/fncSelect_exam_detail_que', { testCode, testCodeMun }, (res) => {
            if (res && Array.isArray(res)) {

                console.log(res, '>>>>>>>>>>>>>>>>> setList_pdf_marking');
                dispatch(setList_pdf_marking(res));
                setIsModal_marking(true);
            } else {
                console.log(res, "kuesbExamRoutes/fncSelect_exam_list_marking");
            }
        });
    };

  function fncSelect_exam_list_complete (testCode, testCodeMun) {
        const acaCode = fnc.getCookie('acaCode');
        const stCode = fnc.getCookie('stCode');

        fnc.sendAxios('/select/exam/fncSelect_exam_detail_study', { stCode, testCode, testCodeMun }, (res) => {
            if (res && Array.isArray(res)) {
                dispatch(setList_pdf_complete(res));
                setIsModal_complete(true);
            } else {
                console.log(res, "fncSelect_exam_list_complete");
            }
        });
    };


    function onClickDownload(item) {   
        const rtn = window.confirm('파일을 다운로드하시겠습니까?');
        if(!rtn) return null;

        const fileFolder = item.fileFolder;
        const fileName = item.fileName;
        const fileNameCode = item.fileNameCode;
        const remoteFilePath = `${fileFolder}/${fileNameCode}`;
 
        fnc.sendAxiosBlob(`/downloadSftp`, {remoteFilePath, fileNameCode }, (res) => {
            if (res) {        
                const url = window.URL.createObjectURL(new Blob([res], { type: 'application/octet-stream' }));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileName);  // 다운로드할 파일 이름 설정
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);  // 생성된 URL 해제
            } else {
                console.log('Error during file download');
            }
        }); 
     }


     function onClickGradeCut() {//등급컷
        setIsModal_gradeCut(true);
     }

     function onClickDownload2(item) {//이건 사용 안 하지만 지우지 마 (sftp없이 다운로드)
        const fileFolder = item.fileFolder;
        const fileName = item.fileName;
        const fileNameCode = item.fileNameCode;
        const remoteFilePath = `${fileFolder}/${fileNameCode}`;
 
        fnc.sendAxiosBlob(`/downloadLocal`, {remoteFilePath, fileNameCode }, (res) => {
            if (res) {        
                const url = window.URL.createObjectURL(new Blob([res], { type: 'application/octet-stream' }));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileName);  // 다운로드할 파일 이름 설정
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);  // 생성된 URL 해제
            } else {
                console.log('Error during file download');
            }
        }); 
     }

     
    function onClickReport(item) {//report
        setIsModal_chart(true);
    }

      
    function onClickReportTec(item) {//report
        setIsModal_chartTec(true);
    }

    function onCopyExamCode() {
                
        const bookCode = selectedItem.value ?? '';
        const bookName = selectedItem.label ?? '';

        if (selectedItems.length === 0) {
            alert('대상을 선택하세요!');
            return;
          }

        fnc.setCookie('kind_clipBoard', 'TEST');
        fnc.setCookie('selectedBookCode_clipBoard', bookCode);
        fnc.setCookie('selectedBookName_clipBoard', bookName);
        fnc.setCookie('selectedBookDetailCode_clipBoard', JSON.stringify(selectedItems));
        fnc.setCookie('selectedBookDetailName_clipBoard', JSON.stringify(selectedItemsName));

        const itemsString = selectedItemsName.join('\n');
        alert(`${bookName} \n${itemsString}\n(코드가 복사되었습니다.)`);

    }

    
//---------------------------checkbox
function handleCheckboxChange(item) {

    const isSelected = selectedItems.includes(item.testCode);
    const newSelectedItems = isSelected
        ? selectedItems.filter(testCode => testCode !== item.testCode)
        : [...selectedItems, item.testCode];
    setSelectedItems(newSelectedItems);

    const newSelectedItemsName = isSelected//시험지명 넣기(exam만 있음 book은 없음)
        ? selectedItemsName.filter(testCode => testCode !== item.testCode)
        : [...selectedItemsName, item.testNm];
    setSelectedItemsName(newSelectedItemsName);

  }
  
  function handleSelectAllChange() {
    if (selectAll) {
        setSelectedItems([]);
        setSelectedItemsName([]);
    } else {
        setSelectedItems(list.map(item => item.testCode));
        setSelectedItemsName(list.map(item => item.testNm));
    }
    setSelectAll(!selectAll);
  }

    //=====================================================================================
    return (
        <div className="Exam2Main">
            <div className="wrap-book-main">
                <section className="section-book-main">
                {<table className="table-hong table-book-main">
                        <thead>
                            <tr>
                                <th>no</th>
                                <th>시험지명</th>
                                <th>문항수</th>
                                <th>응시(명)</th>
                                {userKind == 1 && <th>맞음</th>}
                                {userKind == 1 && <th>성취도</th>}
                                <th>마킹</th>
                                {userKind == 1 && <th>Report</th> }
                                {userKind == 2 && <th>분석</th> }
                                <th>Down</th>
                                <th>등급컷</th>
                                {userKind == 2 && !isBookStudy &&
                                <th style={{width:'4rem'}}>
                                    <Checkbox
                                    checked={selectAll}
                                    onChange={handleSelectAllChange}
                                    />
                                </th>
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {list && list.map((item, index) => {
                                const chejumYN = item.chejumYN;
                                const buttonText = chejumYN === 'Y' ? '완료' : '마킹';
                                const btnClassName = chejumYN === 'Y' ? 'btnMarkingY' : 'btnMarkingN';
                                const correct = chejumYN === 'Y' ? `${item.oCnt}/${item.munCnt}` : '';
                                const score = chejumYN === 'Y' ? Math.round(item.score * 10) / 10 : '';
                                const fileName = item.fileName;
                                const gradeCutCnt = item.gradeCutCnt;

                                const reportButtonStyle = chejumYN !== 'Y' ? { display: 'none' } : {};
                                const downloadButtonStyle = fileName && fileName.trim() !== '' ? {} : { display: 'none' };
                                const gradeCutStyle = gradeCutCnt == 0 ? {display: 'none'} : {  };

                                return (
                                    <tr key={index} onClick={() => onClickTr(item, index)} className="row_testnm_list">
                                        <td> <span>{index + 1}</span> </td>
                                        <td> <span>{item.testNm}</span> </td>
                                        <td> <span>{item.munCnt}</span> </td>
                                        <td> <span>{item.cntMarkingSt}</span> </td>
                                        {userKind == 1 && <td> <span>{correct}</span> </td>}
                                        {userKind == 1 && <td> <span>{score}</span> </td> }
                                        <td>
                                            <Button className={btnClassName}  onClick={() => onClickMarkingOrComplete(item)}>
                                                {buttonText}
                                            </Button>
                                        </td>
                                        {userKind == 1 && 
                                        <td>
                                            <Button className="btnReport" style={reportButtonStyle} 
                                            onClick={() => onClickReport(item)}
                                            startIcon={<img src={imgReport} alt="Report" />}>
                                            </Button>
                                        </td> }

                                        {userKind == 2 && 
                                        <td>
                                            {/* reportButtonStyle여긴 이거 넣으면 안돼 */}
                                            <Button className="btnReport" 
                                            onClick={() => onClickReportTec(item)}
                                            startIcon={<img src={imgReport} alt="Report" />}>
                                            </Button>
                                        </td> }

                                        <td>
                                            <Button className="btnDownload" style={downloadButtonStyle} 
                                                onClick={() => {onClickDownload(item)}}
                                                startIcon={<img src={imgHwp} alt="HWPFile" />}>
                                            </Button>
                                        </td>

                                        <td>
                                            <Button className="btnGadeCut" style={gradeCutStyle}
                                                onClick={() => {onClickGradeCut()}}
                                                >확인
                                            </Button>
                                        </td>

                                        {userKind == 2 && !isBookStudy &&
                                            <td>
                                                <Checkbox
                                                    checked={selectedItems.includes(item.testCode)}
                                                    onChange={() => handleCheckboxChange(item)}
                                                />
                                            </td>
                                        }
                                        
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table> }
                </section>
            </div>

          { userKind == 2 && location.pathname !='/lms' && <section className='section-menu'>
              <Button className='btnExamCodeCopy' onClick={onCopyExamCode}>TEST 코드 복사하기</Button>
            </section> }

          
            {isModal_memo && (
                <>
                    <S.Modal>
                        <section className="section-memo">
                            <MemoCanvas setIsModal={setIsModal_memo} />
                        </section>
                    </S.Modal>
                </>
            )}

            {isModal_marking && (
                <S.Modal>
                    <section className="section-marking">
                        <BookPdf_marking setIsModal={setIsModal_marking} />
                    </section>
                </S.Modal>
            )}

            {isModal_complete && (
                <S.Modal>
                    <section className="section-complete">
                        <Exam5Complete setIsModal={setIsModal_complete} />
                    </section>
                </S.Modal>
            )}

            {isModal_chart && (
                <S.Modal>
                     <HeaderTitle  title={title} direction={1} onBackClick={() => setIsModal_chart(false)} />
                    <div style={{display:'flex', flexDirection:'row', gap: '1rem'}}>
                      
                        <section className="section-chart-detail">
                            <Exam_chartDetail testCode={selectedListItem.testCode} stCode={stCode} />
                        </section>

                        <section className="section-chart">
                            <Exam_ChartSt  testCode={selectedListItem.testCode} stCode={stCode} setIsModal={setIsModal_chart} />
                        </section>

                        </div>
                    
                </S.Modal>
            )}

            {isModal_chartTec && (
                <S.Modal>
                    <section className="section-chart-detail">
                            <Exam_chartDetailTec testCode={selectedListItem.testCode} setIsModalTec={setIsModal_chartTec}/>
                    </section>              
                </S.Modal>
            )}

            {isModal_gradeCut && (
                <S.Modal>
                    <HeaderTitle  title={title} direction={1} onBackClick={() => setIsModal_gradeCut(false)} />
                    <section className="section-gradeCut">
                            <Exam_gradeCut testCodeMun={selectedListItem.testCodeMun} setIsModalTec={isModal_gradeCut}/>
                    </section>              
                </S.Modal>
            )}


        </div>
    );
};

export default Exam2Main;

import React, { useState, useEffect,useRef } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.scss';
import fnc from './mymod/commFunction';
import { useSelector, useDispatch } from 'react-redux';
import { setIsLoggedIn,  setIsModalTestLms } from "./reduxToolkit/redux";
import { useNavigate } from 'react-router-dom';
import NavbarSub from './NavbarSub';
import NavbarMO from './NavbarMO';
import hboiaLogo from './images/kuesb_logo.png'
import { Button } from '@mui/material';



function Navbar() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const isLoggedIn = useSelector(state => state.reduxStore.isLoggedIn);
    const userKind = useSelector(state => state.reduxStore.userInfo.kind);
    const isLoginPage = useSelector(state => state.reduxStore.isLoginPage);
    const version = useSelector(state => state.reduxStore.version);

    const targetRef = useRef(null);  
    const [isOpen, setIsOpen] = useState(false);
    const [activeLink, setActiveLink] = useState('evaluationTec');
    const [isAndroid, setIsAndroid] = useState(null);

    const [showSubmenu, setShowSubmenu] = useState(false);
    const [activeSubLink, setActiveSubLink] = useState(null);

    const menuRef = useRef(null);


    // console.log(isLoggedIn, '  isLoggedIn');


  useEffect(() => {
    if (version && version.toLowerCase().includes('android')) {
      setIsAndroid(true);
    } else {
      setIsAndroid(false);
    }
  }, [version]);


    
    const toggleMenu = () => {
      if (isOpen) {
        setTimeout(() => setIsOpen(false), 100);
      } else {
        setIsOpen(true);
      }
    };

    const onToggleLogin = () => {
      if (isLoggedIn) {
        const rtn = window.confirm("로그아웃하시겠습니까?");
        if (!rtn) return;

        dispatch(setIsLoggedIn(false));
        navigate("/"); //evaluationTec
      } else {
        navigate("/login");
      }
    };

    const onHome = () => {
      navigate('/main');
    };

    const handleLinkClick = (linkName) => {
      setActiveLink(linkName);
      // alert(linkName)
      dispatch(setIsModalTestLms(false));//lms시험에서 열린 창 닫기

    }

    const checkSession = () => {
      // const lastLoginTime = fnc.getCookie("lastLoginTime");
      // const minutesPassed = (Date.now() - lastLoginTime) / 60000; 
      // if (!lastLoginTime || minutesPassed > 30) {//분
      //   alert('시간이 만료되었습니다. \n로그인을 다시 해주세요!');
      //   navigate("/");
      // }else{
      //   fnc.setCookie('lastLoginTime', Date.now(), 1);
      // }
    };

    function onCloseApp() {

      if (typeof window.myJSInterface !== 'undefined'){
          if (window.myJSInterface.CloseAppReact) {
            window.myJSInterface.CloseAppReact('');
          }
      }

      // if (window.myJSInterface.callWebSite) {
      //   window.myJSInterface.callWebSite('https://naver.com');
      // } else {
      //   console.error("callWebSite feature is not available on your platform.");
      // }


    }

    //==========================================서브메뉴
    const toggleSubmenu = () => {
      setShowSubmenu(prev => !prev);
    };

    useEffect(() => {
      const handleClickOutside = (event) => {
        if (showSubmenu && menuRef.current && !menuRef.current.contains(event.target)) {
          setShowSubmenu(false);
        }
      };
    
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [showSubmenu]);
    
    const handleSubLinkClick = (linkName) => {
      setActiveSubLink(linkName);
      // setShowSubmenu(false); // 서브메뉴를 닫지 않음
    };
    
    

//=====================================================================
    return (
      <>
        <nav className="navbar" onClick={checkSession} >
          <div className="navbar-logo" onClick={onHome}>
            {/* <img src={hboiaLogo} alt="logo" /> */}
            <div className="txt-logo">
              <p>강 원 학 교</p>     
              <p>KUESB LMS</p>      
              
            </div>
          </div>
          <div className={`navbar-menu`}>
          {isLoggedIn && (
              <Link to="/digital" 
                className={`link-hover-underline ${ activeLink === "digital" ? "active" : "" }`}
                onClick={() => handleLinkClick("digital")} >
                디지털 1.0
              </Link>
            )}

          {isLoggedIn && (
              <Link to="/book" 
                className={`link-hover-underline ${ activeLink === "book" ? "active" : "" }`}
                onClick={() => handleLinkClick("book")} >
                 디지털 2.0
              </Link>
            )}

          {isLoggedIn && (
              <Link to="/exam" 
                className={`link-hover-underline ${ activeLink === "exam" ? "active" : "" }`}
                onClick={() => handleLinkClick("exam")} >
                진단평가
              </Link>
            )}

          {/* {isLoggedIn && userKind == 2 && (
              <Link to="/courseRegTec" 
                className={`link-hover-underline ${ activeLink === "courseRegTec" ? "active" : "" }`}
                onClick={() => handleLinkClick("courseRegTec")} >
                수강신청(T)
              </Link>
            )} */}

      
          {isLoggedIn && userKind == 2 && (
              <Link to="/lmsTec" 
                className={`link-hover-underline ${ activeLink === "lmsTec" ? "active" : "" }`}
                onClick={() => handleLinkClick("lmsTec")} >
                LMS생성
              </Link>
            )}

          {isLoggedIn && (
              <Link to="/lms" 
                className={`link-hover-underline ${ activeLink === "lms" ? "active" : "" }`}
                onClick={() => handleLinkClick("lms")} >
                { userKind == 2 ? 'LMS검증': 'LMS'}
              </Link>
            )}

          {isLoggedIn && userKind == 2 && (
              <Link to="/LmsTecResultApp" 
                className={`link-hover-underline ${ activeLink === "LmsTecResult" ? "active" : "" }`}
                onClick={() => handleLinkClick("LmsTecResult")} >
                학생결과
              </Link>
            )}


            {isLoggedIn && userKind == 1 && (
              <Link to="/courseReg" 
                className={`link-hover-underline ${ activeLink === "courseReg" ? "active" : "" }`}
                onClick={() => handleLinkClick("courseReg")} >
                수강신청
              </Link>
            )}


          {/* {isLoggedIn && userKind == 2 && (
              <Link to="/basicSetting" 
                className={`link-hover-underline ${ activeLink === "book3" ? "active" : "" }`}
                onClick={() => handleLinkClick("book3")} >
                기초설정
              </Link>
            )} */}

        <div ref={menuRef} className="navbar-menu2">
          {isLoggedIn && userKind == 2 && (
              <Link to="#" 
                className={`link-hover-underline ${activeLink === "book4" ? "active" : ""}`}
                onClick={(e) => {
                  e.preventDefault(); // Prevent navigating
                  handleLinkClick("book4");
                  toggleSubmenu();
                }}>
                <span className="link-content">
                  수강신청
                <i className="fa-solid fa-caret-down"></i>
                </span>
              </Link>
            )}

          {showSubmenu && (
            <div className="submenu2">
              {isLoggedIn && userKind == 2 && (
                <Link to="/courseRegTec"
                  className={`submenu-link ${activeSubLink === 'courseRegTec' ? 'active' : ''}`}
                  onClick={() => {
                    setActiveSubLink('courseRegTec');
                    setShowSubmenu(false);
                  }}>
                  수강신청(T)
                </Link>
              )}
              {isLoggedIn && (
                <Link to="/courseReg"
                  className={`submenu-link ${activeSubLink === 'courseReg' ? 'active' : ''}`}
                  onClick={() => {
                    setActiveSubLink('courseReg');
                    setShowSubmenu(false);
                  }}>
                  수강신청
                </Link>
              )}
              {isLoggedIn && userKind == 2 && (
                <Link to="/basicSetting"
                  className={`submenu-link ${activeSubLink === 'basicSetting' ? 'active' : ''}`}
                  onClick={() => {
                    setActiveSubLink('basicSetting');
                    setShowSubmenu(false);
                  }}>
                  기초설정
                </Link>
              )}
            </div>
          )}

        </div>



          </div>

          <div className="box_navar-login">
            <Button
              className="navbar-login"
              onClick={() => navigate("/updateapp")}
            >     
              {isLoggedIn ? <i className="fas fa-user-circle"></i> : ""}
            </Button>

            {<Button className="navbar-login" onClick={onToggleLogin}>
              {isLoggedIn ? "로그아웃" : "로그인"}
            </Button>}

           {isLoginPage && isAndroid && <Button className="closeApp" onClick={onCloseApp}>
              종료
            </Button>}

          </div>
        </nav>

        <nav className="navbar-mo">
          <div
            className={`navbar-menu-mo ${
              isOpen ? "navbar-menu-active-mo" : ""
            }`}
          >
            <NavbarMO isOpen={isOpen} setIsOpen={setIsOpen} />
          </div>

          <div className="navbar-toggler" onClick={toggleMenu}>
            <i className={`fas ${isOpen ? "fa-times" : "fa-bars"}`}></i>
          </div>
        </nav>
      </>
    );
}

export default Navbar;

import './Exam5Complete.scss';
import React, { useState, useEffect } from "react";
import HeaderTitle from '../comm/HeaderTitle';
import BookPdf_marking_menu from './Exam4Marking_menu';
import BookPdf_marking_omr from '../page_book/BookPdf_marking_omr';
import imgO  from '../images/svg/O2.png';
import imgX  from '../images/svg/X2.png';
import { useSelector, useDispatch } from 'react-redux';
import { setHeader_dev, initializeMarkingList, setList_que, setRowIdx_que, setKind } from '../reduxToolkit/redux';
import { Button } from '@mui/material';

const Exam5Complete = ({ setIsModal }) => {
    const dispatch = useDispatch();

    const imgUrl = process.env.REACT_APP_imgUrl;
    const list = useSelector(state => state.reduxStore.list_pdf_complete);
    const rowIdx_pdf = useSelector(state => state.reduxStore.rowIdx_pdf);

    const markingList = useSelector(state => state.reduxStore.markingList);

    const [title, setTitle] = useState('');
    const [selectedListItem, setSelectedListItem] = useState({});
    const [record_que, setRecord_que] = useState([]);
    const [OX, setOX] = useState("O");

    useEffect(() => {
      dispatch(setHeader_dev('Exam5Complete'));
    }, []);

    useEffect(() => {

        const listQue = getRecords_pdf_detail_que(list);

        setRecord_que(listQue);
        dispatch(initializeMarkingList(listQue.length));
        dispatch(setKind('sol'));
        setSelectedListItem(setList_que[rowIdx_pdf]);
        dispatch(setList_que(listQue));
        
     }, [list, dispatch]);

    

    function getRecords_pdf_detail_que(userJsonObj) {
        let records = [];
        for (let i = 0; i < userJsonObj.length; i++) {
            let source = userJsonObj[i]['source'];
            let testCode = userJsonObj[i]['testCode'];
            let testCodeMun = userJsonObj[i]['testCodeMun'];
            let folder = userJsonObj[i]['folder'].replaceAll('\\', '/');
            let comQue = userJsonObj[i]['comQue'];//공통지문
            let queNo = userJsonObj[i]['queNo'];//일련번호 문항코드
            let imgFileName = `${queNo}.gif`;
     
            let imgCol = userJsonObj[i]['imgCol'];
            let ImgSize = userJsonObj[i]['ImgSize'];
            let ImgSizeSol = userJsonObj[i]['ImgSizeSol'];
            let kind = '객관식';

            const markingBar = userJsonObj[i]['markingBar'];
            const dapBar = userJsonObj[i]['dapBar'];
            const oxBar = userJsonObj[i]['oxBar'];

            const dap = userJsonObj[i]['dap'];// const dap = dapBar.split('|')[i];
            const marking = markingBar.split('|')[i];  
            const OX = oxBar.split('|')[i];

            let rnd = Math.floor(Math.random() * 900) + 100;
            let imagePath = `${imgUrl}/${folder}/${imgFileName}?${rnd}`;
            const imagePath_sol =  `${imgUrl}/${folder}/풀이_${imgFileName}?${rnd}`;

            records.push({
                source, testCode, testCodeMun , imagePath, folder, imgFileName, queNo, dap, 
                imgCol, ImgSize, ImgSizeSol, imagePath_sol, comQue, kind,
                dap, marking, OX,
            });
        }
        return records;
    }

  
    //---------------------------------------------------------------------------
    return (
      <div className="Exam5Complete">
        {/* <HeaderTitle title={title} direction={1} onBackClick={() => setIsModal(false)}/> */}
        <Button className='btnClose-me' onClick={() => setIsModal(false)} >
            <i class="fa-solid fa-xmark"></i>
        </Button> 
        <div className="wrap-que">
          <section className="section-que">
            {record_que.map((item, index) => (
              <article className="article-que" key={index}>
               
                <span className="No">
                  {index + 1}
                  <img  className="img-OX"  src={item.OX === 'O' ? imgO : imgX} alt="imgOX"/>
                </span>
                <div className="box-imgQue">
                  <img
                    className={`imgQue ${item.imgCol}`}
                    src={item.imagePath}
                    alt={`${item.pdfFileName}`}
                  />
                </div>
                <p className="menu">
                  <BookPdf_marking_menu
                    list_que={record_que}
                    item={item}
                    no={index + 1}
                    markingListValue={markingList[index + 1]}
                    isComplete={true}
                  />
                </p>
              </article>
            ))}
    
          </section>

          <section className="section-omr">
            {/* <BookPdf_marking_omr /> */}
          </section>

       

        </div>
      </div>
    );
}

export default Exam5Complete;

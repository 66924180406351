import './Detail_download.scss';
import * as S from '../scss/theme';
import React, { useState, useEffect } from "react";
import fnc from '../mymod/commFunction';
import Button from '@mui/material/Button';
import Combobox from '../comm/Combobox_noBMK';
import Select from 'react-select';
import HeaderTitle from "../comm/HeaderTitle";
import { useDispatch, useSelector } from 'react-redux';
import { setIsModalTest, bookCode, setBookCode } from '../reduxToolkit/redux';
import Exam2Main from '../page_exam/Exam2Main';
import BookMain from '../page_book/BookMain';


const Detail_download = ({}) => {

    const fileFolder = process.env.REACT_APP_fileFolder;
    const dispatch = useDispatch();
    // const classCode = useSelector(state => state.reduxStore.classCode);
    // const lessonDate = useSelector(state => state.reduxStore.lessonDate);
    const lmsListCode = useSelector(state => state.reduxStore.lmsListCode);

    const [list, setList] = useState([]);
    const [selectedIdx, setSelectedIdx] = useState(0);
    const [isRefresh, setIsRefresh] = useState(false);
    const [selectedUnitItem, setSelectedUnitItem] = useState({});
    const [isModalTest, setIsModalTest] = useState(false);
    const [bookName, setBookName] = useState(null);
    const [bookTestKind, setBookTestKind] = useState(null);
   
    useEffect(() => {
      fncSelect_list();
    }, [isRefresh]);

    
  function fncSelect_list() {
    const acaCode = fnc.getCookie('acaCode');
    const stCode = fnc.getCookie('stCode');
    
    fnc.sendAxios('/select/lms/course/fileDownload', { acaCode, parentCode: lmsListCode }, (res) => {
      if (res && Array.isArray(res)) { 
        setList(getRecords(res));
        // console.log(res, '----------- res');
      } else {
        console.log(res,"select/lms/course/studyTest");
      }
    });
  }

  
  function getRecords(res) {
    let records = [];
    let kind = null;
    for (let i = 0; i < res.length; i++) { 
        const sno = res[i]["sno"];     
        const fileCode= res[i]["fileCode"];
        const fileName = res[i]["fileName"];
        const kind = res[i]["kind"];
        const parentCode = res[i]["parentCode"];
        const code = res[i]["code"];
        records.push({ sno, fileCode, fileName, kind, parentCode, code });
    }
    return records;
  }

  function onClickTr() {

  }

  
//   function onClickDownload(item) { 
    
    
//     const rtn = window.confirm('파일을 다운로드하시겠습니까?');
//     if(!rtn) return null;
//     if (window.myJSInterface && window.myJSInterface.callWebSite) {

//       const downloadUrl = 'http://13.209.121.231/downloadNewWindow';
//       window.myJSInterface.callWebSite(downloadUrl);
//     }
//  }
  

  function onClickDownload(item) { 
        
    const rtn = window.confirm('파일을 다운로드하시겠습니까?');
    if(!rtn) return null;

    // const fileFolder = item.fileFolder;
    //  const fileFolder = "/web/kuesbLmsFileUpload/1000"
    const fileName = item.fileName;
    const fileNameCode = item.fileCode;
    const remoteFilePath = `${fileFolder}/${fileNameCode}`;
    console.log(remoteFilePath, 'remoteFilePath');

    // console.log(fileName, 'fileName');
    // console.log(fileNameCode, 'fileNameCode');
    // console.log(remoteFilePath, 'remoteFilePath');

    fnc.sendAxiosBlob(`/downloadSftp`, {remoteFilePath, fileNameCode }, (res) => {
        if (res) {        
            const url = window.URL.createObjectURL(new Blob([res], { type: 'application/octet-stream' }));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);  // 다운로드할 파일 이름 설정
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);  // 생성된 URL 해제
        } else {
            console.log('Error during file download');
        }
    }); 
 }

 
// function onClickDownload(item) {   
//   const rtn = window.confirm('파일을 다운로드하시겠습니까?');
//   if (!rtn) return null;

//   const fileFolder = "/web/kuesbLmsFileUpload/1000";
//   const fileName = item.fileName;
//   const fileNameCode = item.fileCode;
//   const remoteFilePath = `${fileFolder}/${fileNameCode}`;

//   fnc.sendAxiosBlob(`/downloadSftp`, { remoteFilePath, fileNameCode }, (res) => {
//     if (res) {
//       const url = window.URL.createObjectURL(new Blob([res], { type: 'application/octet-stream' }));
//       const link = document.createElement('a');
//       link.href = url;
//       link.setAttribute('download', decodeURIComponent(fileName)); // 다운로드할 파일 이름 설정 및 디코딩
//       document.body.appendChild(link);
//       link.click();
//       document.body.removeChild(link);
//       window.URL.revokeObjectURL(url);  // 생성된 URL 해제
//     } else {
//       console.log('Error during file download');
//     }
//   }); 
// }

// function onClickDownload_newWindow(item) {   
//   const rtn = window.confirm('파일을 다운로드하시겠습니까?');
//   if (!rtn) return null;

//   const fileFolder = "/web/kuesbLmsFileUpload/1000";
//   const fileName = item.fileName;
//   const fileNameCode = item.fileCode;
//   const remoteFilePath = `${fileFolder}/${fileNameCode}`;

//   fnc.sendAxiosBlob(`/downloadSftp`, { remoteFilePath, fileNameCode }, (res) => {
//     if (res) {
//       const url = window.URL.createObjectURL(new Blob([res], { type: 'application/octet-stream' }));
//       const encodedFileName = encodeURIComponent(fileName);
//       const downloadUrl = `${url}#filename=${encodedFileName}`;
//       // const downloadUrl = `https://naver.com`;
// console.log(downloadUrl, ' downloadUrl');
//       if (window.myJSInterface && window.myJSInterface.callWebSite) {
//         window.myJSInterface.callWebSite(downloadUrl);
//       } else {
//         const newWindow = window.open(downloadUrl, '_blank');
//         if (!newWindow) {
//           console.error("새 창을 열 수 없습니다. 팝업 차단기가 활성화되어 있을 수 있습니다.");
//         }
//       }

//       // URL 해제
//       window.URL.revokeObjectURL(url);
//     } else {
//       console.log('Error during file download');
//     }
//   });
// }



  
    //=================================================
    return (
      list.length>0 && (<div className="Detail_download">
        <div className="title">파일 다운로드</div>
        <div className="wrap-main">
          <section className="section-main">
            <table className="table-hong table-main">
              <thead>
                <tr>
                  <th>종류</th>
                  <th>파일명</th>
                  <th>다운로드</th>
                </tr>
              </thead>

              <tbody>
                {list &&
                  list.map((item, index) => (
                    <tr key={index} onClick={() => onClickTr(item, index)}>
                      <td>{item.kind}</td>
                      <td>{item.fileName}</td>
                      <td>
                        <Button
                          className="btnDownload"
                          onClick={() => onClickDownload(item)}
                        >
                          다운
                        </Button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>


          
         
         
          </section>

          {isModalTest && (
               <section className='section-exam'>
                <div className="box_modal_test">
                  <HeaderTitle onBackClick={()=>setIsModalTest(false)} title={bookName} direction={1}/>
                    {bookTestKind === 'TEST' ? (
                    <Exam2Main selectedItem={selectedUnitItem} />
                    ) : (
                    <BookMain selectedItem={selectedUnitItem} />
                    )}     
                </div>
              </section>
            )}
          
        </div>
      </div>
      )
    );
}

export default Detail_download;
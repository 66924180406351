import './GroupMain.scss';
import * as S from '../scss/theme';
import React, { useState, useEffect } from "react";
import fnc from '../mymod/commFunction';
import Button from '@mui/material/Button';
import Combobox from '../comm/Combobox_noBMK';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { setList_pdf, setHeaderInfo,setIsRefresh_studentCourseList } from '../reduxToolkit/redux';



const GroupMain = ({selectedItem, handleChange}) => {


    const dispatch = useDispatch();
   

    const [list, setList] = useState([]);
    const [groupName, setGroupName] = useState(null);
    const [groupCode, setGroupCode] = useState(null);

    const [kwamokCode, setKwamokCode] = useState(null);

    const [selectedIdx, setSelectedIdx] = useState(0);
    const [isRefresh, setIsRefresh] = useState(false);
    const [options1, setOptions1] = useState([]);//강사
    const [selectedOption1, setSelectedOption1] = useState(null);

    useEffect(() => {
      fncSelect_list();
      fncSelect_kwamok();         
    }, [isRefresh]);

    
  function fncSelect_list() {
    const acaCode = fnc.getCookie('acaCode');
    const stCode = fnc.getCookie('stCode');
    
    fnc.sendAxios('/select/basic/group/groupList', { acaCode }, (res) => {
      if (res && Array.isArray(res)) { 
        const listGroup = getRecords(res);
        setList(listGroup);
      } else {
        console.log(res,"select/basic/group/groupList");
      }
    });
  }

  
  function getRecords(res) {
    let records = [];
    for (let i = 0; i < res.length; i++) {      
        const clGroupCode= res[i]["clGroupCode"];
        const clKwamokCode = res[i]["clKwamokCode"];
        const clGroupName = res[i]["clGroupName"];//BookPdf에서는 page, source를 사용한다.
        const clKwamokName = res[i]["clKwamokName"];
        const sDate = res[i]["sDate"];
             
        records.push({ clGroupCode, clKwamokCode, clGroupName, clKwamokName, sDate });
    }
    return records;
  }

  //--------------------------------------------------과목리스트

  function fncSelect_kwamok() {
    const acaCode = fnc.getCookie('acaCode');
    const stCode = fnc.getCookie('stCode');
    
    fnc.sendAxios('/select/basic/group/kwamok', { acaCode }, (res) => {
      if (res && Array.isArray(res)) { 
        const cmbListKwamok = getRecords_kwamok(res);
        setOptions1(cmbListKwamok);
      } else {
        console.log(res,"select/basic/group/kwamok");
      }
    });
  }

  function getRecords_kwamok(res) {
    let records = [];
    
    for (let i = 0; i < res.length; i++) {      
          const kwamokCode = res[i]["kwamokCode"];
          const name = res[i]["kwamok"];
          records.push({ value: kwamokCode, label: name });
    }
    return records;
  }



  //---------------------------------------------------------------------------------버튼 기능

  function onRefresh() {
    setIsRefresh(!isRefresh);
    // setSelectedOption1(null);
  }

  function onInsert() {
    // console.log(selectedOption1, ' selectedOption1');
    const acaCode = fnc.getCookie('acaCode');
    const clGroupCode = fnc.fncSirialNum('c');
    const clGroupName = groupName ?? '';
    const clKwamokCode = selectedOption1?.value ?? '';
    const sDate = fnc.fncDate();

    setGroupCode(clGroupCode);//삭제용

    if(!clGroupName) {
      alert('과정을 입력하세요!');
      return null;
    }

    if(!clKwamokCode) {
      alert('과목을 선택하세요!');
      return null;
    }

    const isGroupName = list.some((item) => item.clGroupName === groupName);
    if (isGroupName) {
      alert('같은 과정명이 존재합니다. \n예시와 같이 수정하세요');
      return;
    }


    const rtn = window.confirm(`${clGroupName}을 등록하시겠습니까?`);
    if(!rtn) return;

    fnc.sendAxios('/insert/basic/group/groupName', { acaCode, clGroupCode, clGroupName, clKwamokCode, sDate }, (res) => {
      if (res) {     
        onRefresh(); 
        alert('완료되었습니다.');
      } else {
        console.log(res,"insert/basic/group/groupName");
      }
    });
  }

  function onUpdate() {
    const acaCode = fnc.getCookie('acaCode');
    const eDate = fnc.fncDate();
    const clGroupCode = groupCode;
    const clGroupName = groupName ?? '';
    const clKwamokCode = selectedOption1 ? selectedOption1.value : ''; 

    if(!clGroupName) {
      alert('그룹을 선택하세요!');
      return null;
    }
    const rtn = window.confirm(`${clGroupName}을 수정하시겠습니까?`);
    if(!rtn) return;

    fnc.sendAxios('/update/basic/group/group', { acaCode, clGroupCode, clGroupName, clKwamokCode, eDate }, (res) => {
      if (res) {     
        onRefresh(); 
        alert('완료되었습니다.');
      } else {
        console.log(res,"update/basic/gangsa");
      }
    });
  }

  function onDelete() {
      const acaCode = fnc.getCookie('acaCode');
      const clGroupCode = groupCode;

      if(!clGroupCode) {
        alert('과정을 선택하세요!');
        return null;
      }

      const rtn = window.confirm(`${groupName}을 삭제하시겠습니까?`);
      if(!rtn) return;

      fnc.sendAxios('/delete/basic/group/groupCode', { acaCode, clGroupCode }, (res) => {
        if (res) { 
            setIsRefresh(!isRefresh);
        } else {
          console.log(res,"delete/basic/group/groupCode");
        }
      });
    
  }

  function onClickTr( item, index) {
    setSelectedIdx(index);//값넣기는 일단 실패
    setGroupName(item.clGroupName);
    // const kwamokCode = list[index].clKwamokCode;
    // const groupCode = list[index].clGroupCode;

    console.log(item);
    setGroupCode(item.clGroupCode);
    setKwamokCode(item.clKwamokCode);

  }

  
    //=========================================
    return (

      <div className="GroupMain">
        <div className="wrap-course-main">
          <section className="section-book-main">
            <table className="table-hong table-gangsa-main">
              <thead>
                <tr>
                  <th>No</th>
                  <th>과정</th>
                  <th>과목</th>
                  <th>날짜</th>
                </tr>
              </thead>

              <tbody>
                  {list && list.map((item, index) => (
  
                      <tr key={index} onClick={() => onClickTr(item, index)}>  
                        <td>{index+1}</td>
                        <td>{item.clGroupName}</td>
                        <td>{item.clKwamokName}</td>
                        <td>{item.sDate}</td>
                      </tr>
                  ))}
            
                </tbody>

            </table>
          </section>
        </div>

        <section className='section-input'>

          <div className='box-cmb'>
            <span className='lbl-cmb'>*과정을 입력하세요! (예: 고1-수학-정규반)</span>
            <div className='cmb'>
      
               <input type='text' className='txtGroupName' 
                value={groupName}
                spellCheck='false'
                onChange={(e) => setGroupName(e.target.value)}>
                </input>
            </div>  
          </div>

          
          <div className='box-cmb'>
            <span className='lbl-cmb'>*과목을 선택하세요!</span>
            <div className='cmb'>
                <Combobox options={options1}  handleChange={(item) => setSelectedOption1(item)}
                 initialComboValue={kwamokCode}  
                 />
            </div>  
          </div>

         
            
                    
        </section>

        <section className='section-btn'>
          <Button className='btn btnRefresh' onClick={onRefresh}>새로고침</Button> 
          <Button className='btn btnInsert' onClick={onInsert}>추가</Button> 
          <Button className='btn btnUpdate' onClick={onUpdate}>수정</Button> 
          <Button className='btn btnDelete' onClick={onDelete}>삭제</Button>  
        </section>        
      </div>
    );
}

export default GroupMain;
import './DigitalMain.scss';
import * as S from '../scss/theme';
import React, { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import fnc from '../mymod/commFunction';
import Button from '@mui/material/Button';
import imgPencil from '../images/svg/pencil.svg';
import youtube from '../images/svg/youtube.svg';
import youtube_gray from '../images/svg/youtube-gray.svg';
import DigitalPdf from './DigitalPdf';
import MemoCanvas from '../drawSvg/MemoCanvas';
import Checkbox from '@mui/material/Checkbox';
import VideoReact from '../video/VideoReact';

import { useDispatch, useSelector } from 'react-redux';
import { setList_pdf, setRowIdx_pdf, setKind, setList_pdf_marking, setList_pdf_complete, 
          setSelectedPage_pdf, setHeaderInfo, setSelectedBookDetailCode, setIsBookStudy } from '../reduxToolkit/redux';

const DigitalMain = ({selectedItem, handleChange}) => {//selectedItem이건 bookCode: 1345임 value: 1345가 아니고
    const videoUrl = process.env.REACT_APP_VIDEO_URL;
    const location = useLocation();
    const imgUrl = process.env.REACT_APP_imgUrl;
    const dispatch = useDispatch();

    const selectedUnit_pdf = useSelector(state => state.reduxStore.selectedUnit_pdf);
    const isRefresh_bookMain = useSelector(state => state.reduxStore.isRefresh_bookMain);
    const userKind = useSelector(state => state.reduxStore.userInfo.kind);
    const selectedBookDetailCode = useSelector(state => state.reduxStore.selectedBookDetailCode);
    const isBookStudy = useSelector(state => state.reduxStore.isBookStudy);
    
    const [list, setList] = useState([]);
    const [isModal, setIsModal] = useState(false);
    const [isModal_video, setIsModal_video] = useState(false);
    
    const [isModal_memo, setIsModal_memo] = useState(false);
    const [isModal_marking, setIsModal_marking] = useState(false);
    const [isModal_complete, setIsModal_complete] = useState(false);
    const [selectedListItem, setSelectedListItem] = useState({}); 
    const [selectedItems, setSelectedItems] = useState([]);//체크박스
    const [selectAll, setSelectAll] = useState(false);//체크박스
    const [isModal_chart, setIsModal_chart] = useState(false);
    const [isModal_chartTec, setIsModal_chartTec] = useState(false);
    const [title, setTitle] = useState('');
    const stCode = fnc.getCookie('stCode');
    const [selectedSource, setSelectedSource] = useState('');
    const [selectedPage, setSelectedPage] = useState('');
    const [fullUrl, setFullUrl] = useState('');


    useEffect(() => {
      
      if(selectedBookDetailCode.length > 0) {//lms에서 in절로 오는 부분
        const detailCode =  selectedBookDetailCode.join(',');
        fncSelect_pdf_list_detailCode(selectedItem.bookCode, detailCode)

      }else {
        fncSelect_pdf_list(selectedItem.bookCode);
      }
 
    }, [selectedItem, isRefresh_bookMain]);

    
  function fncSelect_pdf_list(bookCode, index) {
    const acaCode = fnc.getCookie('acaCode');
    const stCode = fnc.getCookie('stCode');

    fnc.sendAxios('/select/book/pdfList_digital', { acaCode, stCode, bookCode}, (res) => {

      // console.log({ acaCode, stCode, bookCode});
      // console.log(res, '========digital res');
      if (res && Array.isArray(res)) { //단원선택 클릭 시 결과 (오른쪽 페이지 list)    
        const listPdf = getRecords_pdf_detail_pdf(res);
        setList(listPdf);
        dispatch(setList_pdf(listPdf));
      } else {
        console.log(res,"select/book/pdfList");
      }
    });
  }

  
  function fncSelect_pdf_list_detailCode(bookCode, detailCode) {
    const acaCode = fnc.getCookie('acaCode');
    const stCode = fnc.getCookie('stCode');
    

    fnc.sendAxios('/select/book/pdfList_digital_detailCode', { acaCode, stCode, bookCode, detailCode }, (res) => {
      if (res && Array.isArray(res)) { //단원선택 클릭 시 결과 (오른쪽 페이지 list)

        const listPdf = getRecords_pdf_detail_pdf(res);
        setList(listPdf);
        dispatch(setList_pdf(listPdf));
        dispatch(setSelectedBookDetailCode([]));//초기화를 해줘야 출판물 문제 클릭 시 전체가 나옴
        // dispatch(setIsBookStudy(false));//여기 넣으면 안 됨 각각 넣어야함
      } else {
        console.log(res,"select/book/pdfList");
      }
    });
  }
  
  function getRecords_pdf_detail_pdf(res) {
    let records = [];
    for (let i = 0; i < res.length; i++) {      
        const sno= res[i]["sno"];
        const bookCode = res[i]["bookCode"];
        const page = res[i]["page"];//정수 in절에 사용
        const fileName = res[i]["fileName"];//BookPdf에서는 page, source를 사용한다.
        const qrCode = res[i]["qrCode"];
        const testCode = bookCode;//source; //이 두개는 indexDB에 필요함
        const munCode = fileName;//pdfFileName;//이 두개는 indexDB에 필요함

        let rnd = Math.floor(Math.random() * 900) + 100;
        let imagePath = `${imgUrl}/pdf/${bookCode}/${fileName}?${rnd}`;
        records.push({ sno, page, bookCode, fileName, qrCode, imagePath, testCode, munCode});
    }
    return records;
  }


  
  function onClickTr( item, index) {
    setSelectedListItem(item);
    dispatch(setRowIdx_pdf(index));
    dispatch(setSelectedPage_pdf(item.page));
    // console.log(item, '========== item');
    const label =  `${selectedUnit_pdf} ${item.page}쪽`;
    dispatch(setHeaderInfo({title: label, direction: -1}));
  }

  function onClickMemo () {
    setIsModal_memo(true);
    dispatch(setKind('pdf'));//indexDB기록용
  }

  function onClickYoutube (item) {

    if (!item.qrCode) {
      alert('영상이 없습니다.');
      return null;
    }
    const fileName=`0000/${item.qrCode}`;
    const path =`${videoUrl}/${fileName}`;
    setFullUrl(path)

    setIsModal_video(true);

  }

function onCopyBookCode() {

  const bookCode = selectedItem.bookCode ?? '';//value말고 이걸로 해도 되나 exam에서는 value만 됨
  const bookName = selectedItem.label ?? '';

  if (selectedItems.length === 0) {
    alert('대상을 선택하세요!');
    return;
  }

  fnc.setCookie('kind_clipBoard', '디지털');
  fnc.setCookie('selectedBookCode_clipBoard', bookCode);
  fnc.setCookie('selectedBookName_clipBoard', bookName);
  fnc.setCookie('selectedBookDetailCode_clipBoard', JSON.stringify(selectedItems));
  fnc.setCookie('selectedBookDetailName_clipBoard', JSON.stringify(selectedItems));


  alert(`${bookName} \n${selectedItems}\n(코드가 복사되었습니다.)`);
           


}

//---------------------------checkbox
function handleCheckboxChange(item) {

  const isSelected = selectedItems.includes(item.page);
  const newSelectedItems = isSelected
      ? selectedItems.filter(page => page !== item.page)
      : [...selectedItems, item.page];
  setSelectedItems(newSelectedItems);
}

function handleSelectAllChange() {
  if (selectAll) {
      setSelectedItems([]);
  } else {
      setSelectedItems(list.map(item => item.page));
  }
  setSelectAll(!selectAll);
}

    //=========================================
    return (
      //자식 컨포넌트
      <div className="DigitalMain">
        <div className="wrap-book-main">
          <section className="section-book-main">
          {<table className="table-hong table-book-main">
              <thead>
                <tr>
                  <th>페이지</th>
                  <th>필기</th>
                  <th>영상</th>

                {userKind == 2 && !isBookStudy &&
                  <th style={{width:'4rem'}}>
                    <Checkbox
                    checked={selectAll}
                    onChange={handleSelectAllChange}
                    />
                  </th>
                }
                </tr>
              </thead>

              <tbody>
                  {list && list.map((item, index) =>                    
                    (
                      <tr key={index} onClick={() => onClickTr(item, index)}>
                        <td>
                          <Button className="btnPage" onClick={() => setIsModal(true)}>
                            {item.page}
                          </Button>
                        </td>
                        <td>
                          <Button
                            className="btnPage_draw"
                            onClick={onClickMemo}
                            startIcon={<img src={imgPencil} alt="Pencil" />}
                          />
                        </td>

                        <td>
                          <Button
                            className="btnPage_youtube"
                            onClick={() => onClickYoutube(item)}
                            startIcon={
                              item.qrCode ?
                                <img src={youtube} alt="youtube" /> :
                                <img src={youtube_gray} alt="youtube" />
                            }
                          />
                        </td>
     
                        {userKind == 2 && !isBookStudy &&
                          <td>
                              <Checkbox
                                  checked={selectedItems.includes(item.page)}
                                  onChange={() => handleCheckboxChange(item)}
                              />
                          </td>
                        }
                      </tr>
       
        
                  ))}
                </tbody>

            </table>}
          </section>




        </div>
        {userKind == 2 && location.pathname !='/lms' && <section className='section-menu'>
              <Button className='btnBookCodeCopy' onClick={onCopyBookCode}>교재코드 복사하기</Button>
        </section> }

        {isModal && (//pdf 보기
          <S.Modal>
            <section className="section-pdf">
              <DigitalPdf setIsModal={setIsModal} />
            </section>
          </S.Modal>
        )}

        {isModal_memo && (//pdf 메모하기
          <>
            <S.Modal>
              <section className="section-memo">
                <MemoCanvas setIsModal={setIsModal_memo} />
              </section>
            </S.Modal>

          </>
        )}

      {isModal_video && (//video
        <>
          <S.Modal>
            <section className="section-video">
              <VideoReact fullUrl={fullUrl} setIsModal={setIsModal_video} />
            </section>
          </S.Modal>
        </>
      )}

  
        
      </div>
    );
}

export default DigitalMain;
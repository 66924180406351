import './BookMain.scss';
import * as S from '../scss/theme';
import React, { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import fnc from '../mymod/commFunction';
import Button from '@mui/material/Button';
import imgPencil from '../images/svg/pencil.svg';
import BookPdf from './BookPdf';
import BookPdf_marking from './BookPdf_marking';
import MemoCanvas from '../drawSvg/MemoCanvas';
import DrawMenu from '../drawSvg/DrawMenu';
import imgReport from '../images/svg/report.svg';
import { useDispatch, useSelector } from 'react-redux';
import { setList_pdf, setRowIdx_pdf, setKind, setList_pdf_marking, setList_pdf_complete, 
          setSelectedPage_pdf, setHeaderInfo, setSelectedBookDetailCode, setIsBookStudy } from '../reduxToolkit/redux';
import BookPdf_complete from './BookPdf_complete';
import Checkbox from '@mui/material/Checkbox';
import Book_chartDetail from './Book_chartDetail';
import HeaderTitle from "../comm/HeaderTitle";
import BookChartSt from './BookChartSt';
import Book_chartDetailTec from './Book_chartDetailTec';

const BookMain = ({selectedItem, handleChange}) => {//selectedItem이건 bookCode: 1345임 value: 1345가 아니고
    const location = useLocation();
    const imgUrl = process.env.REACT_APP_imgUrl;
    const dispatch = useDispatch();

    const selectedUnit_pdf = useSelector(state => state.reduxStore.selectedUnit_pdf);
    const isRefresh_bookMain = useSelector(state => state.reduxStore.isRefresh_bookMain);
    const userKind = useSelector(state => state.reduxStore.userInfo.kind);
    const selectedBookDetailCode = useSelector(state => state.reduxStore.selectedBookDetailCode);
    const isBookStudy = useSelector(state => state.reduxStore.isBookStudy);
    
    const [list, setList] = useState([]);
    const [isModal, setIsModal] = useState(false);
    const [isModal_memo, setIsModal_memo] = useState(false);
    const [isModal_marking, setIsModal_marking] = useState(false);
    const [isModal_complete, setIsModal_complete] = useState(false);
    const [selectedListItem, setSelectedListItem] = useState({}); 
    const [selectedItems, setSelectedItems] = useState([]);//체크박스
    const [selectAll, setSelectAll] = useState(false);//체크박스
    const [isModal_chart, setIsModal_chart] = useState(false);
    const [isModal_chartTec, setIsModal_chartTec] = useState(false);
    const [title, setTitle] = useState('');
    const stCode = fnc.getCookie('stCode');
    const [selectedSource, setSelectedSource] = useState('');
    const [selectedPage, setSelectedPage] = useState('');
   
    useEffect(() => {

      if(selectedBookDetailCode.length > 0) {
        const detailCode =  selectedBookDetailCode.join(',');
        fncSelect_pdf_list_detailCode(selectedItem.bookCode, detailCode)
      }else {
        fncSelect_pdf_list(selectedItem.bookCode);
      }
 
    }, [selectedItem, isRefresh_bookMain]);

    
  function fncSelect_pdf_list(bookCode, index) {
    const acaCode = fnc.getCookie('acaCode');
    const stCode = fnc.getCookie('stCode');

    fnc.sendAxios('/select/book/pdfList', { acaCode, stCode, source: bookCode}, (res) => {
      if (res && Array.isArray(res)) { //단원선택 클릭 시 결과 (오른쪽 페이지 list)
        setList(res);
        const listPdf = getRecords_pdf_detail_pdf(res);
        dispatch(setList_pdf(listPdf));
      } else {
        console.log(res,"select/book/pdfList");
      }
    });
  }

  
  function fncSelect_pdf_list_detailCode(bookCode, detailCode) {
    const acaCode = fnc.getCookie('acaCode');
    const stCode = fnc.getCookie('stCode');
    

    fnc.sendAxios('/select/book/pdfList_detailCode', { acaCode, stCode, source: bookCode, detailCode }, (res) => {
      if (res && Array.isArray(res)) { //단원선택 클릭 시 결과 (오른쪽 페이지 list)
        // console.log(res, ' bookCode list');
        setList(res);
        const listPdf = getRecords_pdf_detail_pdf(res);
        dispatch(setList_pdf(listPdf));
        dispatch(setSelectedBookDetailCode([]));//초기화를 해줘야 출판물 문제 클릭 시 전체가 나옴
        // dispatch(setIsBookStudy(false));//여기 넣으면 안 됨 각각 넣어야함
      } else {
        console.log(res,"select/book/pdfList");
      }
    });
  }
  
  function getRecords_pdf_detail_pdf(res) {
    let records = [];
    for (let i = 0; i < res.length; i++) {      
        const pdfFileName= res[i]["pdfFileName"];
        const source = res[i]["source"];
        const page = res[i]["page"];//BookPdf에서는 page, source를 사용한다.
        const cntMarkingSt = res[i]["cntMarkingSt"];
        const testCode = source;
        const munCode = pdfFileName;
        const no = page;
        let rnd = Math.floor(Math.random() * 900) + 100;
        let imagePath = `${imgUrl}/pdf/${testCode}/${pdfFileName}?${rnd}`;

        records.push({ imagePath, testCode, munCode, no, source, page, pdfFileName, cntMarkingSt});
    }
    return records;
  }


  
  function onClickTr( item, index) {
    setSelectedListItem(item);
    dispatch(setRowIdx_pdf(index));
    dispatch(setSelectedPage_pdf(item.page));
    // console.log(item, '========== item');
    const label =  `${selectedUnit_pdf} ${item.page}쪽`;
    dispatch(setHeaderInfo({title: label, direction: -1}));
  }

  function onClickMemo () {
    setIsModal_memo(true);
    dispatch(setKind('pdf'));//indexDB기록용
  }

  //----------------------------------------------pdf marking (문제풀기)
  function onClickMarking(item) {
    fncSelect_pdf_list_marking(item.source, item.page);
  }

  function onClickComplete(item) {
    fncSelect_pdf_list_complete(item.source, item.page);

  }

  
function fncSelect_pdf_list_marking(source, page) {
  fnc.sendAxios('/select/book/fncSelect_pdf_detail_que', { source, page }, (res) => {
    if (res && Array.isArray(res)) { 
      dispatch(setList_pdf_marking(res));
      setIsModal_marking(true);
    } else {
      console.log(res,"fncSelect_pdf_list_marking");
    }
  });
}


function fncSelect_pdf_list_complete( source, page) {
  const acaCode = fnc.getCookie('acaCode');
  const stCode = fnc.getCookie('stCode');

  fnc.sendAxios('/select/book/fncSelect_pdf_detail_que_complete', {acaCode, stCode, source, page}, (res) => {
    if (res && Array.isArray(res)) { 
      // console.log(res, ' fncSelect_pdf_list_complete');
      dispatch(setList_pdf_complete(res));
      setIsModal_complete(true);
    } else {
      console.log(res,"fncSelect_pdf_list_complete");
    }
  });
}

function onCopyBookCode() {

  const bookCode = selectedItem.bookCode ?? '';//value말고 이걸로 해도 되나 exam에서는 value만 됨
  const bookName = selectedItem.label ?? '';

  if (selectedItems.length === 0) {
    alert('대상을 선택하세요!');
    return;
  }

  fnc.setCookie('kind_clipBoard', '출판물');
  fnc.setCookie('selectedBookCode_clipBoard', bookCode);
  fnc.setCookie('selectedBookName_clipBoard', bookName);
  fnc.setCookie('selectedBookDetailCode_clipBoard', JSON.stringify(selectedItems));
  fnc.setCookie('selectedBookDetailName_clipBoard', JSON.stringify(selectedItems));


  alert(`${bookName} \n${selectedItems}\n(코드가 복사되었습니다.)`);
           


}

//---------------------------checkbox
function handleCheckboxChange(item) {

  const isSelected = selectedItems.includes(item.page);
  const newSelectedItems = isSelected
      ? selectedItems.filter(page => page !== item.page)
      : [...selectedItems, item.page];
  setSelectedItems(newSelectedItems);
}

function handleSelectAllChange() {
  if (selectAll) {
      setSelectedItems([]);
  } else {
      setSelectedItems(list.map(item => item.page));
  }
  setSelectAll(!selectAll);
}

function onClickReport(item) {
  // console.log(selectedItem, '=============== selectedItem');
  setSelectedSource(item.source);
  setSelectedPage(item.page);
  setIsModal_chart(true);
}


function onClickReportTec(item) {//report
  setSelectedSource(item.source);
  setSelectedPage(item.page);
  setIsModal_chartTec(true);
}

    //=========================================
    return (
      //자식 컨포넌트
      <div className="BookMain">
        <div className="wrap-book-main">
          <section className="section-book-main">
          {<table className="table-hong table-book-main">
              <thead>
                <tr>
                  <th>페이지</th>
                  <th>필기</th>
                  <th>문항수</th>
                  <th>응시(명)</th>
                {userKind == 1 && <th>맞음</th> }
                {userKind == 1 && <th>성취도</th> }
                  <th>마킹</th>
                {userKind == 1 &&  <th>Report</th> }
                {userKind == 2 && <th>분석</th> }
                {userKind == 2 && !isBookStudy &&
                  <th style={{width:'4rem'}}>
                    <Checkbox
                    checked={selectAll}
                    onChange={handleSelectAllChange}
                    />
                  </th>
                }
                </tr>
              </thead>

              <tbody>
                  {list && list.map((item, index) => 
                    // const chejumYN = item.chejumYN;
                    // const reportButtonStyle = chejumYN !== 'Y' ? { display: 'none' } : {};
                    
                    (
                    
                    item.markingCnt === 0 ? ( // 마킹 전
                      
                      <tr key={index} onClick={() => onClickTr(item, index)}>
                        <td>
                          <Button className="btnPage" onClick={() => setIsModal(true)}>
                            {item.page}
                          </Button>
                        </td>
                        <td>
                          <Button
                            className="btnPage_draw"
                            onClick={onClickMemo}
                            startIcon={<img src={imgPencil} alt="Pencil" />}
                          />
                        </td>
                        <td>{item.munCnt}</td>
                        <td>{item.cntMarkingSt}</td>
                      
                     
                        {userKind == 1 && <td></td> }  {/* 맞음 */}
                        {userKind == 1 && <td></td> }  {/* 성취도 */}
                        
                        <td>
                          <Button  className="btnMarkingN" onClick={() => onClickMarking(item, index)} >
                            마킹
                          </Button>
                        </td>
                        {userKind == 1 && <td></td> }

                        {userKind == 2 && 
                        <td>
                            <Button className="btnReport" 
                            onClick={() => onClickReportTec(item)}
                            startIcon={<img src={imgReport} alt="Report" />}>
                            </Button>
                        </td> }

                        {userKind == 2 && !isBookStudy &&
                          <td>
                              <Checkbox
                                  checked={selectedItems.includes(item.page)}
                                  onChange={() => handleCheckboxChange(item)}
                              />
                          </td>
                        }
                      </tr>
                    ) : (//마킹완료
                      <tr key={index} onClick={() => onClickTr(item, index)}>
                        <td>
                          <Button className="btnPage" onClick={() => setIsModal(true)}>
                            {item.page}
                          </Button>
                        </td>
                        <td>
                          <Button
                            className="btnPage_draw"
                            onClick={onClickMemo}
                            startIcon={<img src={imgPencil} alt="Pencil" />}
                          />
                        </td>
                        <td>{item.munCnt}</td>
                        
                        <td>{item.cntMarkingSt}</td>
                        {userKind == 1 && <td>{item.Ocnt}/{item.markingCnt}</td>}

                        {userKind == 1 &&  
                        <td>
                          {item.markingCnt > 0 ? Math.round((item.Ocnt / item.markingCnt) * 1000) / 10 : 0}%
                        </td>}

                        <td>
                          <Button className="btnMarkingY"  onClick={() => onClickComplete(item, index)} >완료</Button>
                        </td>

                        {userKind == 1 &&  <td>
                            <Button className="btnReport"  
                            onClick={() => onClickReport(item)}
                            startIcon={<img src={imgReport} alt="Report" />}>
                            </Button>
                        </td> }

                      {userKind == 2 && 
                        <td>
                            <Button className="btnReport" 
                            onClick={() => onClickReportTec(item)}
                            startIcon={<img src={imgReport} alt="ReportTec" />}>
                            </Button>
                        </td> }


                        {userKind == 2 && !isBookStudy &&
                          <td>
                              <Checkbox
                                  checked={selectedItems.includes(item.page)}
                                  onChange={() => handleCheckboxChange(item)}
                              />
                          </td>
                        }
                      </tr>
                    )
                  ))}
                </tbody>

            </table> }
          </section>
        </div>
        
        {userKind == 2 && location.pathname !='/lms' && 
        <section className='section-menu'>
              <Button className='btnBookCodeCopy' onClick={onCopyBookCode}>교재코드 복사하기</Button>
        </section> }

        {isModal && (//pdf 보기
          <S.Modal>
            <section className="section-pdf">
              <BookPdf setIsModal={setIsModal} />
            </section>
          </S.Modal>
        )}

        {isModal_memo && (//pdf 메모하기
          <>
            <S.Modal>
              <section className="section-memo">
                <MemoCanvas setIsModal={setIsModal_memo} />
              </section>
            </S.Modal>

          </>
        )}

        {isModal_marking && (//마킹 시험보기
          <S.Modal>
            {/* section-marking 형식상 css없음*/}
            <section className="section-marking">
              <BookPdf_marking setIsModal={setIsModal_marking} />
            </section>
          </S.Modal>
        )}

        {isModal_complete && (//완료 학습
          <S.Modal>
            <section className="section-complete">
              <BookPdf_complete setIsModal={setIsModal_complete} />
            </section>
          </S.Modal>
        )}

          {isModal_chart && (
                <S.Modal>
                    <HeaderTitle  title={title} direction={1} onBackClick={() => setIsModal_chart(false)} />
                    <div className='box-chartDetailSt'>  
                       
                        <section className="section-chart-detail">
                            <Book_chartDetail source={selectedSource} page={selectedPage} stCode={stCode} />
                        </section>

                        <section className="section-chart">
                            <BookChartSt source={selectedSource} page={selectedPage} stCode={stCode}  setIsModal={setIsModal_chart} />
                        </section>

                      </div>
                    
                </S.Modal>
            )}

            {isModal_chartTec && (
                <S.Modal>
                    <section className="section-chart-detail">
                            <Book_chartDetailTec source={selectedSource} page={selectedPage} setIsModalTec={setIsModal_chartTec}/>
                    </section>              
                </S.Modal>
            )}
        
      </div>
    );
}

export default BookMain;
import './Detail_testMakeTec.scss';
import * as S from '../scss/theme';
import React, { useState, useEffect } from "react";
import fnc from '../mymod/commFunction';
import Button from '@mui/material/Button';
import Combobox from '../comm/Combobox_noBMK';
import Select from 'react-select';
import HeaderTitle from "../comm/HeaderTitle";
import { useDispatch, useSelector } from 'react-redux';
import { setIsModalTest, bookCode, setBookCode } from '../reduxToolkit/redux';
import Exam2Main from '../page_exam/Exam2Main';
import BookMain from '../page_book/BookMain';
import { Value } from 'sass';
import { json } from 'react-router-dom';

const Detail_testMakeTec = ({}) => {


    const dispatch = useDispatch();
   
    const classCode = useSelector(state => state.reduxStore.classCode);
    const className = useSelector(state => state.reduxStore.className);
    const lessonDate = useSelector(state => state.reduxStore.lessonDate);
    // const isModalTest = useSelector(state => state.reduxStore.isModalTest);
    

    const [list, setList] = useState([]);
    const [selectedIdx, setSelectedIdx] = useState(0);
    const [isRefresh, setIsRefresh] = useState(false);


    const [kind, setKind] = useState(false);
    const [bookName, setBookName] = useState(null);
    const [bookCode, setBookCode] = useState(null);
    const [kind2, setKind2] = useState(false);

    

    useEffect(() => {
      fncSelect_list();
      const kind = fnc.getCookie('kind_clipBoard');
      setKind(kind);
    }, [isRefresh]);

    
  function fncSelect_list() {
    const acaCode = fnc.getCookie('acaCode');
    const stCode = fnc.getCookie('stCode');
    
    fnc.sendAxios('/select/lmsTec/course/testMake', { acaCode, classCode, lessonDate }, (res) => {
      if (res && Array.isArray(res)) { 
        setList(getRecords(res));
      } else {
        console.log(res,"select/lmsTec/course/testMake");
      }
    });
  }

  
  function getRecords(res) {
    let records = [];
    let kind = null;
    for (let i = 0; i < res.length; i++) {      
        const bookCode= res[i]["bookCode"];
        const bookName = res[i]["bookName"];
        const classCode = res[i]["classCode"];
        const className = res[i]["className"];
        const code = res[i]["code"];
        const kind = res[i]["kind"];
        const sno = res[i]["sno"];
        const detailCode = res[i]["detailCode"];
        const detailName = res[i]["detailName"];
        const detailCnt = res[i]["detailCnt"];
        const kind2 = res[i]["kind2"];
              
        records.push({ bookCode, bookName, classCode, className, code, kind, sno, detailCode, detailName, detailCnt, kind2 });
    }
    return records;
  }

  
  function onClickTr( item, index) {
    setSelectedIdx(index);//값넣기는 일단 실패
    // setIsModalTest(true);
   
  }

  function onDelete(item) {
    const acaCode = fnc.getCookie('acaCode');
    const stCode = fnc.getCookie('stCode');
    const sno = item.sno;
    const bookName = item.bookName;

    const rtn = window.confirm(`${bookName}을 \n삭제하시겠습니까?`);
    if (!rtn) return;

    fnc.sendAxios('/delete/lmsTec/course/testMake', { acaCode, sno }, (res) => {
      if (res) { 
        setIsRefresh(!isRefresh);
      } else {
        console.log(res,"delete/lmsTec/course/testMake");
      }
    });

  }

  //---------------------------------입력부분

  const handlePasteClick = () => {

      const kind = fnc.getCookie('kind_clipBoard');
      const bookName = fnc.getCookie('selectedBookName_clipBoard');
      const bookCode = fnc.getCookie('selectedBookCode_clipBoard');

      if (!bookCode) {
      alert(`단원을 먼저 복사하고 붙여넣으세요!`);
      return;
      }

      setBookName(bookName);
      setBookCode(bookCode);
      setKind(kind);

  };

  const handleSaveClick = (item) => {

    const acaCode = fnc.getCookie('acaCode');
    const stCode = fnc.getCookie('stCode');
    const sno = item.sno;
    const bookName = fnc.getCookie('selectedBookName_clipBoard');
    const bookCode = fnc.getCookie('selectedBookCode_clipBoard');
    const detailCode = fnc.getCookie('selectedBookDetailCode_clipBoard');
    const detailName = fnc.getCookie('selectedBookDetailName_clipBoard');
    const code = fnc.fncSirialNum('U');

    console.log(detailCode, 'detailCode clipBoard');
    let detailCodeOne = '';

    // const detailCnt = JSON.parse(detailCode).length;

    let detailCnt;
    try {
      detailCnt = JSON.parse(detailCode).length;
    } catch (error) {
      console.error('Invalid JSON:', error);
      alert('잘못된 단원 정보입니다. 다시 시도해 주세요.');
      return;
    }

    if (detailCnt == 1) {
      detailCodeOne = JSON.parse(detailCode)[0];

      console.log(detailCodeOne, 'detailCodeOne');
    }

    if (!bookName) {
      alert(`단원을 먼저 복사하고 붙여넣으세요!`);
      return;
      }

    const rtn = window.confirm(`${bookName}\n${detailName}의 \n${detailCnt}개를 추가하시겠습니까?`);
    if (!rtn) return;

    fnc.sendAxios('/save/lmsTec/course/testMake', 
        { acaCode, classCode, className, kind, lessonDate , bookCode, bookName, code, detailCode, detailName, 
          detailCnt, kind2, detailCodeOne }, (res) => {
      if (res) { 
        setIsRefresh(!isRefresh);
      } else {
        console.log(res,"save/lmsTec/course/testMake");
      }
    });
  };

  function onDetailShow(item) {
    alert(item.detailName);
  }
  
  const handleComboBoxChange = (event) => {
    setKind2(event.target.value);
  };

    //=================================================
    return (
      <div className="Detail_testMakeTec">
        <div className="title">학습 및 진단평가</div>
        <div className="wrap-main">
          <section className="section-main">
            <table className="table-hong table-main">
              <thead>
                <tr>
                  <th>항목</th>
                  <th>단원</th>
                  <th>개수</th>
                  <th>상세</th>
                  <th>구분</th>
                  <th>삭제</th>
                </tr>
              </thead>

              <tbody>
                {list &&
                  list.map((item, index) => (
                    <tr key={index} onClick={() => onClickTr(item, index)}>
                      <td>{item.kind}</td>
                      
                      <td>{item.bookName}</td>
                      <td>{item.detailCnt}</td>
                      <td>
                        <Button className='btnDetailShow' onClick={() => onDetailShow(item)}>상세</Button>
                      </td>
                      <td>{item.kind2}</td>
                      <td>
                        <Button className="btnDelete" onClick={() => onDelete(item)} >
                          {'삭제'}
                        </Button>
                      </td>

                    </tr>
                  ))}
              </tbody>
            </table>
         
          </section>

          <section className='section-inputTestMake'>

            <label htmlFor="textbookName" className="lblKind">{kind}</label>
            
            <input 
              type="text" 
              id="textbookName" 
              readOnly 
              placeholder="붙여넣기 버튼을 클릭하세요." 
              value={bookName}
            />

            <Button className='btnPaset'  onClick={handlePasteClick} >
            붙여넣기
            </Button>

            <select value={kind2} onChange={handleComboBoxChange} className="comboBox">
              <option value=""></option>
              <option value="진도">진도</option>
              <option value="과제">과제</option>
              <option value="시험">시험</option>
            </select>


            <Button className='btnInsert' onClick={handleSaveClick} >
            단원추가
            </Button>
           
           
          </section>
  
        </div>
      </div>
    );
}

export default Detail_testMakeTec;
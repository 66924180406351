import './BookPdf_marking_menu.scss';
import * as S from '../scss/theme';

import MemoCanvas from '../drawSvg/MemoCanvas';
import VideoReact from '../video/VideoReact';
// import DrawMenu from '../drawSvg/DrawMenu';

import React, { useEffect, useState} from "react";
import { useDispatch, useSelector } from 'react-redux';
import { initializeMarkingList, updateMarkingList, setKind, setRowIdx_que, setHeaderInfo } from '../reduxToolkit/redux';



const BookPdf_marking_menu = ({list_que, item, no, markingListValue, isComplete}) => {

    const videoUrl = process.env.REACT_APP_VIDEO_URL;

    const dispatch = useDispatch();
    // const markingList = useSelector(state => state.reduxStore.markingList); 
    // 이 컨포넌트에는 markingList 죽야함( 문항수만큼 컨포넌트가 있어서 버튼 클릭하면 부모컨포넌트에서 markingList가 바뀌므로 문항수만큼 실행됨 )
    const selectedUnit_pdf = useSelector(state => state.reduxStore.selectedUnit_pdf);
    const headerInfo = useSelector(state => state.reduxStore.headerInfo);
    const selectedPage_pdf = useSelector(state => state.reduxStore.selectedPage_pdf);

    const [markingValue, setMarkingValue] = useState('');
    const [isModal_memo, setIsModal_memo] = useState(false);
    const [isModal_video, setIsModal_video] = useState(false);
    const [fullUrl, setFullUrl] = useState('');

// console.log(item);
    useEffect(() => {
      setMarkingValue(markingListValue);
    }, [markingListValue]);

   function onClickNo(value) { 
      dispatch(updateMarkingList({ no: no, value: value }));
      setMarkingValue(value);
    };

    const onInputChange = (e) => {
      const value = e.target.value;
      setMarkingValue(value);
      dispatch(updateMarkingList({ no: no, value: value }));
  };
  
  function onClickMemo_que() {
    setIsModal_memo(true);
    dispatch(setKind('que'));//pdf와 que 두개로 나눔
    dispatch(setRowIdx_que(no-1));

    const label =  `${selectedUnit_pdf} ${selectedPage_pdf}쪽 ${no}번`;
    dispatch(setHeaderInfo({title: label, direction: -1}));
  }

  
  function onClickMemo_sol() {
    setIsModal_memo(true);
    dispatch(setKind('sol'));//pdf와 que 두개로 나눔
    dispatch(setRowIdx_que(no-1));

    const label =  `${selectedUnit_pdf} ${selectedPage_pdf}쪽 ${no}번`;
    dispatch(setHeaderInfo({title: label, direction: -1}));
  }

  
  function showPlay_que(item) {
    // console.log(item, '=========item');
    // const fileName = `0000/020103180101_10081.mp4`
    if (!item.qrCode) {
      alert('영상이 없습니다.');
      return null;
    }
    const fileName=`0000/${item.qrCode}`;
    const path =`${videoUrl}/${fileName}`;
    setFullUrl(path);
    setIsModal_video(true);
  }

  
  //================================================
  return (
    <>
      {item.kind === "개념" && (
        <div className="BookPdf_marking_menu">
          <input className="OX" type="text" />
          <span className="realNo">{item.realNo}</span>
          <span className="kind">{item.kind}</span>
          <input
            className="markingValue"
            type="text"
            value={isComplete ? item.marking : markingValue}
            readOnly
            spellCheck="false"
            autoComplete="off"
          />
          <input
            type="button"
            class={item.qrCode ? "existQrCode" : "notQrCode"}
            onClick={() => showPlay_que(item)}
            value=""
          />
          {!isComplete && <input type="button" className={`markingBtnConcept ${
              markingValue === "학습완료" ? "active" : ""
            }`}
            onClick={() => onClickNo("학습완료")}
            value="학습완료"
          /> }
          {!isComplete && <input type="button" className={`markingBtnConcept ${
              markingValue === "미완료" ? "active" : ""
            }`}
            onClick={() => onClickNo("미완료")}
            value="미완료"
          /> }

          <input class="btnMemo" type="button" value="" onClick={onClickMemo_que}/>
          {/* 개념은 풀이버튼 없어 */}
          {false && <input class="btnMemoBoardWrite" type="button" value="" /> }
        </div>
      )}

      {item.kind === "객관식" && (
        <div className="BookPdf_marking_menu">
          <input className="OX" type="text" />
          <span className="realNo">{item.realNo}</span>
          <span className="kind">{item.kind}</span>
          <input
            className="markingValue"
            type="text"
            // value={markingValue}
            value={isComplete ? item.marking : markingValue}
            readOnly
            spellCheck="false"
            autoComplete="off"
          />

          {!isComplete && [1, 2, 3, 4, 5].map((value) => (//1, 2, 3, 4, 5 버튼
            <input
              key={value}
              className={`markingBtn ${markingValue === value ? "active" : ""}`}
              type="button"
              value={value}
              onClick={() => onClickNo(value)}
            />
          ))}

          <input class="btnMemo" type="button" value="" onClick={onClickMemo_que}/>
          {isComplete && <input class="btnSolPreview" type="button" value="" onClick={onClickMemo_sol}/> }
         
          {false && <input class="btnMemoBoardWrite" type="button" value="" /> }
        </div>
      )}

      {item.kind === "주관식" && (
        <div className="BookPdf_marking_menu">
          <input className="OX" type="text" />
          <span className="realNo">{item.realNo}</span>
          <span className="kind">{item.kind}</span>
          <input className="markingValue" type="text"
            value={isComplete ? item.marking : markingValue}
            onChange={onInputChange}
            spellCheck="false"
            autoComplete="off"
          />

          <input class="btnMemo" type="button" value="" onClick={onClickMemo_que} />
          {isComplete && <input class="btnSolPreview" type="button" value="" onClick={onClickMemo_sol}/> }
          
          {false && <input class="btnMemoBoardWrite" type="button" value="" /> }
        </div>
      )}
      {/*----------------------------------------------------------------메모 */}
      {isModal_memo && (
        <>
          <S.Modal>
            <section className="section-memo">
              <MemoCanvas setIsModal={setIsModal_memo} item={item} />
            </section>
          </S.Modal>
        </>
      )}

    {isModal_video && (
        <>
          <S.Modal>
            <section className="section-video">
              <VideoReact fullUrl={fullUrl} setIsModal={setIsModal_video} item={item} />
            </section>
          </S.Modal>
        </>
      )}


    </>
  );
};

export default BookPdf_marking_menu;

import './Exam6FastSearch.scss';
import React, { useEffect, useState } from 'react';
import HeaderTitle from '../comm/HeaderTitle';
import fnc from '../mymod/commFunction';
import { Button } from '@mui/material';
import { useDispatch } from 'react-redux';
import { setHeaderInfo, setIsBookStudy } from '../reduxToolkit/redux';

const Exam6FastSearch = ({setIsFastSearch, setSelectedUnitItem}) => {
  const [teacherId, setTeacherId] = useState('');
  const [unit1Options, setUnit1Options] = useState([]);
  const [unit2Options, setUnit2Options] = useState([]);
  const [unit3Options, setUnit3Options] = useState([]);
  const [unit4Options, setUnit4Options] = useState([]);
  const [unit5Options, setUnit5Options] = useState([]);
  const [unit6Options, setUnit6Options] = useState([]);
  const [unit7Options, setUnit7Options] = useState([]);
  const [selectedNodeKey, setSelectedNodeKey] = useState(null);
  const [selectedNodeName, setSelectedNodeName] = useState('');
  const [testList, setTestList] = useState([]);
  const [activeButtons, setActiveButtons] = useState({});
  const dispatch = useDispatch();

  useEffect(() => {
    // fetchOptions('ROOT', setUnit1Options);
    fncComboRestore();
  }, []);

  
  
  useEffect(() => {//리스트 복원
    const unitName='unit6_exam';
    const key = fnc.getCookie_pure(unitName);
    const activeItem = unit6Options.filter(pre => pre.childKey === key);
    if (activeItem.length > 0) {
      const nodeKeyValue = activeItem[0].childKey;
      const nodeName = activeItem[0].childName;
      const bookCode = activeItem[0].bookCode;

      fncSelectTestnmList(nodeKeyValue, nodeName, bookCode)
    }

  }, [unit6Options])


  function fncComboRestore() {
    let key, unitName;

    fetchOptions('ROOT', setUnit1Options);

    unitName='unit1';
    key = fnc.getCookie_pure(`${unitName}_exam`);

    if (key) {
      fetchOptions_restore(key, setUnit2Options, unitName);
    }

    unitName='unit2';
    key = fnc.getCookie_pure(`${unitName}_exam`);

    if (key) {
      fetchOptions_restore(key, setUnit3Options, unitName);
    }

    unitName='unit3';
    key = fnc.getCookie_pure(`${unitName}_exam`);
  
    if (key) {
      fetchOptions_restore(key, setUnit4Options, unitName);
    }

    
    unitName='unit4';
    key = fnc.getCookie_pure(`${unitName}_exam`);
    if (key) {
      fetchOptions_restore(key, setUnit5Options, unitName);
    }
    
    unitName='unit5';
    key = fnc.getCookie_pure(`${unitName}_exam`);
    if (key) {
      fetchOptions_restore(key, setUnit6Options, unitName);
    }

    unitName='unit6';
    key = fnc.getCookie_pure(`${unitName}_exam`);
    if (key) {
      fetchOptions_restore(key, setUnit7Options, unitName);
    }

  }


  const fetchOptions = (parentKey, setOptions) => {//setOptions은 setUnit1Options의 주소 연결임
    fncSelect_exam_list_fastSearch(parentKey, setOptions);
  };

  const fncSelect_exam_list_fastSearch = (parentKey, setOptions) => {
    const userId = teacherId;

    fnc.sendAxios('/select/exam/fastSearch', { parentKey, userId }, (res) => {
      if (res && Array.isArray(res)) {
        setOptions(res);
      } else {
        console.log(res, 'fncSelect_exam_list_complete');
      }
    });
  };

  
  const fetchOptions_restore = (parentKey, setOptions, unitName) => {//setOptions은 setUnit1Options의 주소 연결임
    fncSelect_exam_list_fastSearch_restore(parentKey, setOptions, unitName);
  };

  const fncSelect_exam_list_fastSearch_restore = (parentKey, setOptions, unitName) => {
    const userId = teacherId;

    fnc.sendAxios('/select/exam/fastSearch', { parentKey, userId }, (res) => {
      if (res && Array.isArray(res)) {
        setOptions(res);
        setActiveButtons(prevState => ({...prevState, [unitName]: parentKey}));
      } else {
        console.log(res, 'fncSelect_exam_list_complete');
      }
    });
  };

 
  const handleButtonClick = (key, name, unitName, setOptions) => {
    setActiveButtons(prevState => ({
      ...prevState,
      [unitName]: key
    }));


    fnc.setCookie_pure(`${unitName}_exam`, key);
    fetchOptions(key, setOptions);

    // console.log(name, '-name');

    if(unitName =='unit5' || unitName =='unit6') {

      fncSelectTestnmList(key, name);
    }

   


      switch (unitName) {
        case 'unit1':
          setUnit2Options([]);
          setUnit3Options([]);
          setUnit4Options([]);
          setUnit5Options([]);
          setUnit6Options([]);
          setUnit7Options([]);
          setActiveButtons(prevState => ({
            ...prevState,
            unit2: null,
            unit3: null,
            unit4: null,
            unit5: null,
            unit6: null,
            unit7: null
          }));
          fnc.setCookie_pure('unit2_exam', null);
          fnc.setCookie_pure('unit3_exam', null);
          fnc.setCookie_pure('unit4_exam', null);
          fnc.setCookie_pure('unit5_exam', null);
          fnc.setCookie_pure('unit6_exam', null);
          fnc.setCookie_pure('unit7_exam', null);
          break;
        case 'unit2':
          setUnit3Options([]);
          setUnit4Options([]);
          setUnit5Options([]);
          setUnit6Options([]);
          setUnit7Options([]);
          setActiveButtons(prevState => ({
            ...prevState,
            unit3: null,
            unit4: null,
            unit5: null,
            unit6: null,
            unit7: null
          }));
          fnc.setCookie_pure('unit3_exam', null);
          fnc.setCookie_pure('unit4_exam', null);
          fnc.setCookie_pure('unit5_exam', null);
          fnc.setCookie_pure('unit6_exam', null);
          fnc.setCookie_pure('unit7_exam', null);
          break;
        case 'unit3':
          setUnit4Options([]);
          setUnit5Options([]);
          setUnit6Options([]);
          setUnit7Options([]);
          setActiveButtons(prevState => ({
            ...prevState,
            unit4: null,
            unit5: null,
            unit6: null,
            unit7: null
          }));
          fnc.setCookie_pure('unit4_exam', null);
          fnc.setCookie_pure('unit5_exam', null);
          fnc.setCookie_pure('unit6_exam', null);
          fnc.setCookie_pure('unit7_exam', null);
          break;
        case 'unit4':
          setUnit5Options([]);
          setUnit6Options([]);
          setUnit7Options([]);
          setActiveButtons(prevState => ({
            ...prevState,
            unit5: null,
            unit6: null,
            unit7: null
          }));
          fnc.setCookie_pure('unit5_exam', null);
          fnc.setCookie_pure('unit6_exam', null);
          fnc.setCookie_pure('unit7_exam', null);
          break;
        case 'unit5':
          setUnit6Options([]);
          setUnit7Options([]);
          setActiveButtons(prevState => ({
            ...prevState,
            unit6: null,
            unit7: null
          }));
          fnc.setCookie_pure('unit6_exam', null);
          fnc.setCookie_pure('unit7_exam', null);
          break;
        case 'unit6':
          setUnit7Options([]);
          setActiveButtons(prevState => ({
            ...prevState,
            unit7: null
          }));
          fnc.setCookie_pure('unit7_exam', null);
          break;
        default:
          break;
      }

  };

  function fncSelectTestnmList(nodeKeyValue, nodeName) {
    const userId = teacherId;
    const stCode = fnc.getCookie('stCode');
    setSelectedNodeKey(nodeKeyValue);
    setSelectedNodeName(nodeName);

    fnc.sendAxios('/select/exam/fastSearch/testName', { nodeKeyValue, userId, stCode }, (res) => {
      if (res && Array.isArray(res)) {
        setTestList(res);
      } else {
        console.log(res, 'select/exam/fastSearch/testName');
      }
    });
  };

  function onConfirm() {
   if(!selectedNodeKey) {
    alert('대상이 없습니다.');
    return;
   }
    setSelectedUnitItem({value: selectedNodeKey, label: selectedNodeName, bookCode:''});
    dispatch(setHeaderInfo({ title: selectedNodeName, direction: -1 }));
    setIsFastSearch(false);
    dispatch(setIsBookStudy(false));//체크박스 유무(학생이 lms로 들어온 경우)
  }
  //=======================================================

  return (
    <div className="Exam6FastSearch">
      {/* <HeaderTitle onBackClick={() => {setIsFastSearch(false)}} title=" " direction={1} 
        style={{}}
      /> */}

      <Button className='btnClose-me' onClick={() => setIsFastSearch(false)} >
              <i class="fa-solid fa-xmark"></i>
      </Button> 

      <div className="main">
        <div className="main-inner">
            {false && <div className="boxRect">
              <div className="lblTecId">선생님ID</div>
              <div className="unit1">
                <input
                  type="text"
                  className="txtTeacherID"
                  value={teacherId}
                  onChange={(e) => setTeacherId(e.target.value)}
                />
              </div> 
          </div>}
          
          <UnitBox label="개정" options={unit1Options} activeKey={activeButtons.unit1} unitName="unit1" onOptionClick={handleButtonClick} setOptions={setUnit2Options} />
          <UnitBox label="학년" options={unit2Options} activeKey={activeButtons.unit2} unitName="unit2" onOptionClick={handleButtonClick} setOptions={setUnit3Options} />
          <UnitBox label="과목" options={unit3Options} activeKey={activeButtons.unit3} unitName="unit3" onOptionClick={handleButtonClick} setOptions={setUnit4Options} />
          <UnitBox label="단원1" options={unit4Options} activeKey={activeButtons.unit4} unitName="unit4" onOptionClick={handleButtonClick} setOptions={setUnit5Options} />
          <UnitBox label="단원2" options={unit5Options} activeKey={activeButtons.unit5} unitName="unit5" onOptionClick={handleButtonClick} setOptions={setUnit6Options} />
          <UnitBox label="단원3" options={unit6Options} activeKey={activeButtons.unit6} unitName="unit6" onOptionClick={handleButtonClick} setOptions={setUnit7Options} />

          <section className="section-list">
            <div className="box-tb_pdf_list" >
              <table className="table-hong">
                <thead>
                  <tr>
                    <th style={{ width: '5rem' }}>no</th>
                    <th style={{ width: 'auto' }}>시험지명</th>
                    <th style={{ width: '5rem' }}>문항수</th>
                  </tr>
                </thead>
                <tbody>
                  {testList.map((test, index) => (
                    <tr key={index} className="row_testnm_list">
                      <td><span>{index + 1}</span></td>
                      <td style={{ textAlign: 'left', paddingLeft: '0.5rem' }}><span>{test.testNm}</span></td>
                      <td><span>{test.munCnt}</span></td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </section>

          <section className="section-menu-btn">     
               <Button className="btnCancel"  onClick={() => {setIsFastSearch(false)}}>취소</Button>
               <Button className="btnConfirm"  onClick={onConfirm}>확인</Button>
          </section>
        </div>
      </div>
    </div>
  );
};

const UnitBox = ({ label, options, activeKey, unitName, onOptionClick, setOptions }) => {


  return (
    <div className="boxRect">
      <div className="lblUnit">{label}</div>
      <div className="unit">
        {options.map((option, index) => (
          <button
            key={index}
            className={`btnUnit ${activeKey === option.childKey ? 'active' : ''}`}
            onClick={() => onOptionClick(option.childKey, option.childName, unitName, setOptions)}
          >
            <div className="childName">
              {option.childName} {option.testCnt > 0 ? <span className="testCnt">({option.testCnt})</span> : ""}
            </div>
          </button>
        ))}
      </div>
    </div>
  );
};

export default Exam6FastSearch;


import './BasicLogin.scss';
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import axios from "axios";
import fnc from '../mymod/commFunction';
import Cookies from 'js-cookie';
import { useSelector, useDispatch } from 'react-redux';
import { setIsAdmin, setIsLoggedIn, setUserInfo } from '../reduxToolkit/redux';
import { Checkbox } from '@mui/material';

function BasicLogin() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [certCode, setCertCode] = useState('');
  const [userId, setUserId] = useState();
  const [password, setPassword] = useState();
  const userIdRef = useRef(null);  // useRef 훅을 사용해 참조 생성
  const stCode = fnc.getCookie('stCode');//getCookie함수 수정 utf-8에러 남
  const version = useSelector(state => state.reduxStore.version);

  const [appVersion, setAppVersion] = useState(''); // 앱 버전 상태
  const currentVersion = localStorage.getItem('updateVersion');

  useEffect(() => {
    // 컴포넌트가 마운트될 때 input 요소에 포커스를 자동으로 맞춤
    userIdRef.current.focus();
    setAppVersion(version);//초기값 공백으로 해야하고, [version] 붙여야함 안드로이드에서 못 읽어와
  }, [version]);


  const handleSubmit = (event) => {
    event.preventDefault(); // 폼 전송에 의한 페이지 새로고침 방지
    // console.log('로그인 시도:', { userId, password });
    // dispatch(setIsLoggedIn(true));
    // navigate('/home'); 
    if(!userId){
      alert('아이디를 입력하세요!');
      return false;
    }
    if(!password){
      alert('패쓰워드를 입력하세요!');
      return false;
    }
    fncBasicLogin(userId, password)
  };

function fncBasicLogin(id, pw) {

  

  fnc.sendAxios('/kuesbLogin', { id, pw }, (res) => {
    if (res.length) {

      dispatch(setUserInfo(res[0]));
      dispatch(setIsLoggedIn(true));
      fnc.setCookie('id', res[0].id);
      fnc.setCookie('stCode', res[0].stCode);
      fnc.setCookie('userName', res[0].name);
      fnc.setCookie('lastLoginTime', Date.now(), 1);

      fnc.setCookie('acaCode', 1000);

      navigate('/main'); 

      // if(res.token) {
      //   if(basicid === 'admin') {
      //     fnc.setCookie('token', res.token, 1);
      //     // alert( fnc.getCookie('token'))
      //     dispatch(setIsAdmin(true));
      //     dispatch(setIsLoggedIn(true));
      //     navigate('/home'); 
      //   }else{
      //     // fnc.setCookie_pure('token','',1);
      //   }     
      // }

    } else {
      alert('정보가 올바르지 않습니다.')
      console.log(res,"속성이 obj 내에 없습니다.");
    }
  });
}


  //===============================================
  return (
    <div className='BasicLogin' >
  

    <form onSubmit={handleSubmit}>
        <div className='title'>
          <p>강원학교</p>
          <p>K U E S B - L M S</p>
        </div>

      <div> 
        {/* <label htmlFor="userId">* 인증코드</label> */}
          {/* <input
            type="text"
            id="cerCode"
            placeholder='인증코드'
            value={certCode}
            onChange={(e) => setCertCode(e.target.value)}
          /> */}
      </div>

      <div> 
        <label htmlFor="userId">* 아이디</label>
          <input
            type="text"
            id="userId"
            // placeholder='아이디'
            ref={userIdRef}  // input 요소에 ref 속성을 설정
            value={userId}
            onChange={(e) => setUserId(e.target.value)}
            spellCheck='false'
          />
      </div>
      <div>
        <label htmlFor="password">* 비밀번호</label>
        <input
          type="password"
          id="password"
          // placeholder='비밀번호'
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </div>
      <div className='box_menu'>
        <Button type="submit">
            <i className="fa fa-user"></i> 로그인
        </Button>
      </div>

     {/* {!stCode && <div className='box_setingPw'>
        <Button type="submit" onClick={() => navigate('/creatpw')}>초기 비밀번호 설정</Button>
      </div> } */}

     
    </form>
      <section className='section-menu-login'>
      <Button className='btnSignUp' onClick={() => navigate('/signup')}>회원가입 </Button>
        {/* <Button onClick={() => navigate('/signup')}>회원가입 </Button><p>|</p>
        <Button>비밀번호찾기</Button><p>|</p>
        <Button>아이디찾기</Button><p>|</p>
        <Button>계정삭제</Button> */}
          {appVersion && <div>ver {appVersion} ( {currentVersion} )</div>} 
          {!appVersion && <div className='version'>Aplication ver 1.0.1 ( {currentVersion} )</div>}

          
      </section>

        {/* 다운로드 테스트*/}
        
      {/* <section className='download-section'>
        <a href="https://kuesb.i234.me/test.pdf" download="kuesbLogo.png">
          <Button variant="contained" color="primary">
            Download test
          </Button>
        </a>
      </section> */}

    </div>
  );
}

export default BasicLogin;
